import { Box, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import logo from "../../images/card_design.jpg";

const BooknowTopSection = () => {
  return (
    <Paper sx={{ padding: 2, marginTop: "40px" }}>
      <Stack>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Box sx={{ height: "100px", paddingLeft: "30px" }}>
            <Box
              component="img"
              sx={{
                //   padding: "0 12px",
                height: "100%",
                cursor: "pointer",
                borderRadius: "8px",

                // display: { xs: "none", sm: "none", md: "block" },
              }}
              alt="The house from the offer."
              src={logo}
            />
          </Box>
          <Stack sx={{ width: "60%" }}>
            <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 500 }}>
              Wall Calender
            </Typography>
            <Typography variant="p" sx={{ fontSize: "11pt", fontWeight: 500 }}>
              Calendar - 6 Page one side print - 4 Color print - Paper: 120 gsm
              art paper - Spiral binding - Size: 14x19 - Minimum Order: 500 Pcs
            </Typography>
          </Stack>
          <Box sx={{ width: "15%" }}>
            <Typography
              variant="p"
              sx={{ fontSize: "15pt", fontWeight: 600, textAlign: "center" }}
            >
              60 BDT
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default BooknowTopSection;
