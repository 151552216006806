import {
  Facebook,
  Instagram,
  LinkedIn,
  LocationOn,
  Mail,
  PhoneIphone,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import { Container, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import logo from "../../../images/Horekrokom_eCommerce_files/logo.png";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ bgcolor: "black", zIndex: 5 }}>
      <Container>
        <Grid
          container
          rowSpacing={4}
          spacing={{ xs: 0, md: 2 }}
          sx={{ width: "100%", paddingY: "20px" }}
        >
          <Grid item xs={6} md={4} sx={{ order: 0 }}>
            <Stack spacing={2} sx={{ width: "80%" }}>
              <Box
                component="img"
                sx={{
                  //   objectFit: "cover",

                  width: "80px",
                  height: "auto",
                }}
                alt="The house from the offer."
                src={logo}
              />
              <Typography
                variant="p"
                sx={{ color: "#b7b7b7", fontSize: "11pt" }}
              >
                হরেকরকম বাংলাদেশের একটি প্রথম সারির বইয়ের দোকান। আমরা হাজারের
                উপর ইসলামিক, সাধারণ ও একাডেমিক বই বিভিন্ন ডিসকাউন্ট মূল্যে
                বিক্রি করে থাকি। আমরা ভালো প্যাকেজিং এবং স্বল্প শিপিং খরচের
                মাধ্যমে সারাদেশে বই সরবরাহ করে থাকি।
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} sx={{ order: { xs: 2, md: 1 } }}>
            <Grid container spacing={{ xs: 2, md: 6 }}>
              <Grid item xs={6}>
                <Stack spacing={1.5}>
                  <Typography
                    variant="p"
                    sx={{ color: "white", fontSize: "14pt", fontWeight: 600 }}
                  >
                    প্রয়োজনীয় লিংক
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/contact");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    যোগাযোগ করুন
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/blog");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    ব্লগ
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/shoppingcart");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    শপিং ব্যাগ
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/QA");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    প্রশ্নোত্তর
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/shoppingprocess");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    কিভাবে কেনাকাটা করবেন ?
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/terms");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    শর্তাবলী
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/refundpolicy");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    রিফান্ড নীতিমালা
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/privacypolicy");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    প্রাইভেসী পলিসি
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={1.5}>
                  <Typography
                    variant="p"
                    sx={{ color: "white", fontSize: "14pt", fontWeight: 600 }}
                  >
                    জনপ্রিয়
                  </Typography>
                  {/* <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/wishlist");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    আপনার পছন্দের তালিকা
                  </Typography> */}

                  <Typography
                    onClick={() => {
                      navigate("/preorder");
                    }}
                    variant="p"
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    প্রি-অর্ডার
                  </Typography>
                  <Typography
                    onClick={() => {
                      navigate("/package");
                    }}
                    variant="p"
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    প্যাকেজ
                  </Typography>

                  <Typography
                    onClick={() => {
                      navigate("/privacypolicy");
                    }}
                    variant="p"
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "10pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    প্রাইভেসী পলিসি
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4} sx={{ order: { xs: 1, md: 2 } }}>
            <Grid container spacing={1}>
              <Grid item md={3} xs={0}></Grid>
              <Grid item xs={12} md={9}>
                <Stack spacing={1.5}>
                  <Typography
                    variant="p"
                    sx={{ color: "white", fontSize: "14pt", fontWeight: 600 }}
                  >
                    যোগাযোগ
                  </Typography>
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <LocationOn
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <Typography
                      variant="p"
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      Head Office:
                    </Typography>
                  </Stack>
                  <Typography
                    variant="p"
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "11pt",
                      fontWeight: 500,
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    বায়তুল মোকাররম মার্কেট, পুরানা পল্টন, ঢাকা।
                  </Typography>
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <PhoneIphone
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <Typography
                      variant="p"
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        fontWeight: 500,
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      Phone:
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        fontWeight: 500,
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      01786-223347
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        fontWeight: 500,
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      01849-225187
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <Mail
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <Typography
                      variant="p"
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "10pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      info@horekrokombd.com
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <Facebook
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <YouTube
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <Instagram
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <Twitter
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <LinkedIn
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
