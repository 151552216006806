import {
  CancelOutlined,
  FavoriteBorderOutlined,
  Menu,
  Person,
  ShoppingBagOutlined,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { CartContext } from "../App";
import { useCart } from "react-use-cart";

const drawerWidth = 240;
const navItems = [
  "হোম",
  "বই",
  "জেনারেল বই",
  "বিষয়",
  "লেখক",
  "প্রকাশক",
  "বইমেলা",
  "প্রি-অর্ডার",
  "প্রসাধনী",
  "পোশাক",
  "ব্লগ",
  "ষ্টেশনারী",
  "যোগাযোগ করুন",
];

const Header = (props) => {
  let count = 0;

  const [searchedProduct, setSearchedProduct] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [updateCart, setUpdateCart] = useState([]);
  const [cart, setCart] = React.useContext(CartContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (!updateCart) {
      setUpdateCart([]);
    } else {
      setUpdateCart(JSON.parse(localStorage.getItem("cart")));
    }
  }, []);

  const handleSearchFieldChange = (event) => {
    setSearchedProduct(event.target.value);
  };
  const handleCategoryChange = (event) => {
    setProductCategory(event.target.value);
  };

  const handleClick = (path) => {
    if (path === "হোম") {
      navigate("/");
    } else if (path === "বই") {
      navigate("/book");
    } else if (path === "জেনারেল বই") {
      navigate("/general");
    } else if (path === "বিষয়") {
      navigate("/subject");
    } else if (path === "লেখক") {
      navigate("/writer");
    } else if (path === "প্রকাশক") {
      navigate("/publisher");
    } else if (path === "বইমেলা") {
      navigate("/bookfair");
    } else if (path === "প্রি-অর্ডার") {
      navigate("/preorder");
    } else if (path === "প্রসাধনী") {
      navigate("/cosmetic");
    } else if (path === "পোশাক") {
      navigate("/clothings");
    } else if (path === "ষ্টেশনারী") {
      navigate("/stationary");
    } else if (path === "যোগাযোগ করুন") {
      navigate("/contuctus");
    }
  };

  // const handleRemoveFromCartBtn = (product) => {
  //   console.log(product.product_refer);
  //   console.log("clicked", product.product_refer);
  //   let newCart = updateCart.filter(
  //     (element) => element.product_refer !== product.product_refer
  //   );
  //   console.log("lastcard", newCart);

  //   setUpdateCart(newCart);
  //   localStorage.setItem("cart", JSON.stringify(newCart));
  // };
  let reduceWork = true;
  let subTotal = 0;
  if (!updateCart) {
    reduceWork = false;
  } else {
    subTotal = updateCart.reduce(
      (total, product) =>
        total + parseFloat(product.count) * parseFloat(product.product_price),
      0
    );
  }

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        হরেকরকম
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "left" }}>
              <ListItemText
                primary={item}
                onClick={() => {
                  handleClick(item);
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  console.log(updateCart);
  const {
    isEmpty,
    totalItems,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();
  console.log(items);
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav" sx={{ bgcolor: "white" }}>
        <Container>
          <Toolbar sx={{ justifyContent: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <Menu sx={{ color: "black" }} />
            </IconButton>
            <Box
              component="img"
              sx={{
                padding: "0 12px",
                width: 110,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                display: { xs: "none", sm: "none", md: "block" },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/");
              }}
              alt="The house from the offer."
              src={logo}
            />
            <Box
              sx={{
                // width: "60%",
                width: { sm: "80%", md: "60%" },
                display: { xs: "none", sm: "block" },
              }}
            >
              <Toolbar sx={{ padding: "0 12px" }}>
                <FormControl size="small" sx={{ width: "100%" }}>
                  <Toolbar>
                    <OutlinedInput
                      onChange={handleSearchFieldChange}
                      placeholder="Search for products"
                      sx={{
                        width: "70%",
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    />

                    <Select
                      sx={{
                        width: "30%",
                        marginLeft: "-1px",
                        height: "-1px 0",
                        borderRadius: 0,
                      }}
                      value={productCategory}
                      onChange={handleCategoryChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">
                        <Typography variant="p" sx={{ fontSize: "10pt" }}>
                          All Categories
                        </Typography>
                      </MenuItem>
                      <MenuItem value={"বই"}>বই</MenuItem>
                      <MenuItem value={"প্রসাধনী"}>প্রসাধনী</MenuItem>
                      <MenuItem value={"পোশাক"}>পোশাক</MenuItem>
                      <MenuItem value={"ষ্টেশনারী"}>ষ্টেশনারী</MenuItem>
                    </Select>
                    <Button
                      sx={{
                        border: "1px solid #dee2e6",
                        width: "8%",
                        padding: "0.43rem 0",
                        marginLeft: "-1px",
                        borderRadius: 0,
                        borderTopRightRadius: 4,
                        borderBottomRightRadius: 4,
                      }}
                    >
                      <SearchIcon />
                    </Button>
                  </Toolbar>
                </FormControl>
              </Toolbar>
            </Box>

            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "none", lg: "block" },
              }}
            >
              <Toolbar>
                <Box></Box>
                <Stack>
                  <Typography sx={{ color: "black" }} variant="caption">
                    CALL US NOW
                  </Typography>
                  <Typography sx={{ color: "black" }} variant="caption">
                    +88 01700 00000
                  </Typography>
                </Stack>
              </Toolbar>
            </Box>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: { xs: "100%", md: "13%", sm: "13%" } }}
            >
              <Person
                sx={{ color: "black", fontSize: "22pt", padding: "8px" }}
              />
              <FavoriteBorderOutlined
                sx={{ color: "black", padding: "8px", fontSize: "22pt" }}
              />
              <StyledBadge badgeContent={isEmpty ? 0 : totalItems}>
                <PopupState variant="popover" popupId="demo-popup-popover">
                  {(popupState) => (
                    <div>
                      <ShoppingBagOutlined
                        {...bindTrigger(popupState)}
                        sx={{
                          color: "black",
                          padding: "8px",
                          fontSize: "22pt",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      />
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <Stack spacing={1}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                              padding: "10px",
                              bgcolor: "black",
                              color: "white",
                            }}
                          >
                            <Typography variant="p">Items</Typography>
                            <Typography variant="p">View Cart</Typography>
                          </Stack>
                          {items?.map((product) => (
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{ position: "relative" }}
                            >
                              <Stack sx={{ padding: "10px" }}>
                                <Typography variant="body2">
                                  {product.product_name}
                                </Typography>
                                <Typography variant="body2">
                                  {product.quantity} X {product.price}
                                </Typography>
                              </Stack>

                              <Box
                                component="img"
                                sx={{
                                  padding: "0 12px",
                                  width: 40,
                                  height: "auto",
                                }}
                                alt="The house from the offer."
                                src={product.pro_pic}
                              />
                              <CancelOutlined
                                onClick={() => removeItem(product.id)}
                                sx={{
                                  color: "black",
                                  position: "absolute",
                                  top: 2,
                                  right: 10,
                                  zIndex: 5,

                                  fontSize: "12pt",
                                  "&:hover": {
                                    cursor: "pointer",
                                  },
                                }}
                              />
                            </Stack>
                          ))}
                          <Divider />
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography variant="p" sx={{ margin: "10px" }}>
                              Total
                            </Typography>
                            {reduceWork && (
                              <Typography variant="p" sx={{ margin: "10px" }}>
                                {subTotal}
                              </Typography>
                            )}
                          </Stack>
                          <Divider />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={() => {
                                navigate("/shoppingCart");
                              }}
                              sx={{
                                bgcolor: "black",
                                color: "white",
                                width: "92%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              CheckOut
                            </Button>
                          </Box>

                          <Divider />
                        </Stack>
                      </Popover>
                    </div>
                  )}
                </PopupState>
              </StyledBadge>
            </Stack>
          </Toolbar>
          <Box
            sx={{
              width: "100%",
              // width: {sm: '80%', md: '60%',},
              display: { xs: "block", sm: "none", md: "none" },
            }}
          >
            <Toolbar sx={{ padding: "0" }}>
              <FormControl size="small" sx={{ width: "100%" }}>
                <Toolbar>
                  <OutlinedInput
                    onChange={handleSearchFieldChange}
                    placeholder="Search for products"
                    sx={{
                      width: "70%",
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  />

                  <Select
                    sx={{
                      width: "30%",
                      marginLeft: "-1px",
                      height: "-1px 0",
                      borderRadius: 0,
                    }}
                    value={productCategory}
                    onChange={handleCategoryChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                      <Typography variant="p" sx={{ fontSize: "10pt" }}>
                        All Categories
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"বই"}>বই</MenuItem>
                    <MenuItem value={"প্রসাধনী"}>প্রসাধনী</MenuItem>
                    <MenuItem value={"পোশাক"}>পোশাক</MenuItem>
                    <MenuItem value={"ষ্টেশনারী"}>ষ্টেশনারী</MenuItem>
                  </Select>
                  <Button
                    sx={{
                      border: "1px solid #dee2e6",
                      width: "8%",
                      padding: "0.43rem 0",
                      marginLeft: "-1px",
                      borderRadius: 0,
                      borderTopRightRadius: 4,
                      borderBottomRightRadius: 4,
                    }}
                  >
                    <SearchIcon />
                  </Button>
                </Toolbar>
              </FormControl>
            </Toolbar>
          </Box>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 0 }}>
        <Toolbar />
      </Box>
    </Box>
  );
};

// Header.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };
export default Header;
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 8,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "black",
  },
}));
