import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  AppBar,
  Button,
  Divider,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import TabPayment from "./TabPayment";

const PaymentCheckoutLeft = () => {
  const navigate = useNavigate();

  const handleReviewOrder = () => {
    navigate("/revieworder");
  };
  const handleBacktoShipping = () => {
    navigate("/shipping");
  };
  return (
    <Stack spacing={2} sx={{ width: "67%", padding: "15px" }}>
      <Toolbar sx={{ bgcolor: "#F7F7F7" }}>
        <Typography variant="h6">Choose Shipping Method</Typography>
      </Toolbar>
      <Divider />
      <Box sx={{ padding: "16px" }}>
        <TabPayment />
      </Box>
      <Stack direction="row" alignItems="center">
        <Button
          startIcon={<KeyboardArrowLeft />}
          sx={{ width: "50%" }}
          onClick={handleBacktoShipping}
        >
          Back to Shipping
        </Button>
        <Button
          variant="conatined"
          endIcon={<KeyboardArrowRight />}
          onClick={handleReviewOrder}
          sx={{
            width: "50%",
            bgcolor: "black",
            color: "white",
            "&:hover": {
              color: "black",
              backgroundColor: "white",
            },
          }}
        >
          Review the Order
        </Button>
      </Stack>
    </Stack>
  );
};

export default PaymentCheckoutLeft;
