import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Button, Toolbar, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";

function valuetext(value) {
  return `${value}°C`;
}

const SliderPriceRange = () => {
  const [value, setValue] = useState([100, 2000]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ marginBottom: "10px" }}>
      <Slider
        getAriaLabel={() => "Temperature range"}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        min={0}
        max={10000}
      />
      <Stack direction="row" justifyContent="right" alignItems="center">
        <Typography>
          {value[0]} - {value[1]}
        </Typography>
      </Stack>
    </Box>
  );
};

export default SliderPriceRange;
