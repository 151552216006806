import * as React from "react";
import {
  Apps,
  FormatListBulleted,
  HomeOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Slider,
  Toolbar,
  Typography,
} from "@mui/material";
import { Container, Stack } from "@mui/system";
import { Link } from "react-router-dom";
import CustomBreadcrumb from "../components/CustomBreadcrumb";
import SliderPriceRange from "../components/BookPageComponents/SliderPriceRange";
import CategoryList from "../components/BookPageComponents/CategoryList";
import WriterList from "../components/BookPageComponents/WriterList";
import PublisherList from "../components/BookPageComponents/PublisherList";
import ColorsList from "../components/BookPageComponents/ColorsList";
import BookCard from "../components/HomePageComponents/BookSlider/BookCard";
import { useEffect } from "react";
import { useState } from "react";
import { stationeryApi } from "../components/Api/Api";

const Stationery = () => {
  const [age, setAge] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(stationeryApi)
      .then((res) => res.json())
      .then((result) => {
        setData(result);
      });
  }, []);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const breadcrumbs = [
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      // onClick={handleClick}
      style={{ textDecoration: "none" }}
    >
      <HomeOutlined
        fontSize="small"
        sx={{ color: "black", marginBottom: "-4px", marginRight: "10px" }}
      />
      <Typography variant="p" color="text.primary">
        Home
      </Typography>
    </Link>,
    <Typography key="3" variant="p" color="text.primary">
      Books
    </Typography>,
  ];

  return (
    <Box>
      <Box
        sx={{ bgcolor: "#f8f9fa", border: "1px solid #E6E6DF", marginTop: -1 }}
      >
        <CustomBreadcrumb breadcrumbs={breadcrumbs} topic={"বই"} />
      </Box>
      <Container sx={{ marginTop: "2%" }}>
        <Stack direction="row">
          <Paper
            elevation={3}
            sx={{ width: "25%", boxSizing: "border-box", padding: "10px" }}
          >
            <Stack>
              <CategoryList />

              <Divider light sx={{ marginBottom: "10px" }} />

              <Typography variant="subtitle1">PRICE</Typography>
              <SliderPriceRange />

              <Divider light sx={{ marginBottom: "10px" }} />

              <Typography variant="subtitle1">SIZES</Typography>
              <WriterList />

              <Divider light sx={{ marginBottom: "10px" }} />

              <Typography variant="subtitle1">BRANDS</Typography>
              <PublisherList />

              <Divider light sx={{ marginBottom: "10px" }} />

              <Typography variant="subtitle1">COLORS</Typography>
              <ColorsList />
            </Stack>
          </Paper>
          <Stack
            justifyContent="flex-start"
            sx={{ width: "75%", padding: "2%" }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" alignItems="center">
                <Typography variant="p">Sort by:</Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <Select
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                      <em>Default Sorting</em>
                    </MenuItem>
                    <MenuItem value={10}>Sort by popularity</MenuItem>
                    <MenuItem value={30}>Sort by newness</MenuItem>
                    <MenuItem value={30}>Sort by average rating</MenuItem>
                    <MenuItem value={30}>Sort by price: low to high</MenuItem>
                    <MenuItem value={30}>Sort by price: high to low</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography variant="p">Show:</Typography>
                <FormControl sx={{ m: 1, minWidth: 60 }} size="small">
                  <Select
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                      <em>5</em>
                    </MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                    <MenuItem value={16}>16</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ marginRight: "5px" }}
                >
                  <Apps />
                </Button>
                <Button size="small">
                  <FormatListBulleted />
                </Button>
              </Stack>
            </Stack>

            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {data.map((data) => (
                  <Grid item xs={12} sm={4} md={4} key={data.pro_pic}>
                    <BookCard data={data} />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Divider light sx={{ marginTop: "30px", marginBottom: "20px" }} />

            <Pagination
              count={10}
              variant="outlined"
              color="primary"
              sx={{ margin: "auto" }}
            />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Stationery;
