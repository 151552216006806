import { Box } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const BrandCard = (props) => {
  const { data } = props;
  const { br_logo, br_name, br_name_bn, br_refer, btop_pic } = data;
  return (
    <Box sx={{ marginLeft: { xs: 0, md: "20px" }, width: "95%" }}>
      <Box
        component="img"
        sx={{
          objectFit: "cover",
          borderRadius: "12px",
          width: { xs: "100%", md: "95%" },
          height: "150px",
        }}
        alt="The house from the offer."
        src={btop_pic}
      />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: "white",
          width: "60%",
          padding: "15px",
          position: "relative",
          borderRadius: "12px",
          top: "-50px",
          left: { xs: "15%", md: "10.5%" },
        }}
      >
        <Box
          component="img"
          sx={{
            width: "auto",
            height: "60px",
          }}
          alt="The house from the offer."
          src={br_logo}
        />
      </Stack>
    </Box>
  );
};

export default BrandCard;
