import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, Container, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { KeyboardArrowRight } from "@mui/icons-material";
import Slider from "react-slick";
import { useState } from "react";
import { useEffect } from "react";
import { BookTypeApi, BrandsApi } from "../../Api/Api";
import BrandCard from "../Featured Brands/BrandCard";
import "./FeaturedBrand.css";

const BookSlider1 = () => {
  const [data, setData] = useState([]);
  console.log("dataerwrwerewf:", data);
  useEffect(() => {
    fetch(BrandsApi)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setData(result);
      });
  }, []);
  const settings = {
    infinite: true,
    // arrows: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    draggable: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ bgcolor: "#F2F3F5", paddingTop: "20px" }}>
      <Container sx={{ flexGrow: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "0% 0" }}
        >
          <Typography sx={{ fontWeight: 700, fontSize: "15pt" }}>
            জনপ্রিয় পাবলিকেশন্স
          </Typography>
        </Stack>
        <Divider sx={{ marginY: "20px" }} />
        <Box className="brandSlide">
          <Slider {...settings}>
            {data?.map((element) => (
              <Box key={element.pro_pic}>
                <BrandCard data={element} />
              </Box>
            ))}
          </Slider>
        </Box>
      </Container>
    </Box>
  );
};

export default BookSlider1;
