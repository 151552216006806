import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, IconButton, List, ListItem, ListItemText } from '@mui/material';

const EachCategory = (props) => {
    const { data } = props;
    const {
        product_refer,
        product_name,
        price,
        currency,
        pro_pic,
        pro_pic_2,
        category,
        discount
    } = data;

    const categories = [{
        name:'Clothings', quantity: 22},
        {name:'Sunglasses', quantity: 22},
        {name:'Bags', quantity: 62},
        {name:'Watches', quantity: 22},]
    return (
        <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={pro_pic}
          alt="green iguana"
        />
        <CardContent>
        <Typography variant="subtitle1">CATEGORIES</Typography>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {categories.map((value) => (
                    <ListItem
                    key={value}
                    disableGutters
                    sx={{ padding: 0 }} 
                    secondaryAction={
                        <IconButton aria-label="comment" sx={{ bgcolor: 'blue', color: 'white',  }}>
                        <Typography variant='p' sx={{ fontSize: '6pt' }}>{value.quantity}</Typography>
                        </IconButton>
                    }
                    >
                    <ListItemText sx={{ "&:hover": { color: "blue"} }} primary={`${value.name}`}  />
                    </ListItem>
                ))}
            </List>
        </CardContent>
      </CardActionArea>
    </Card>
    );
};

export default EachCategory;