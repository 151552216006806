import {
  AutoStories,
  AutoStoriesRounded,
  CheckroomRounded,
  SaveAsRounded,
} from "@mui/icons-material";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { borderRadius, Box, Stack } from "@mui/system";
import React from "react";
import { GiLipstick } from "react-icons/gi";

const BannerFooter = () => {
  return (
    <Container sx={{ zIndex: 5 }}>
      <Paper sx={{ padding: "20px" }} elevation={1}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Box>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={{
                    width: "45px",
                    height: "45px",
                    backgroundColor: "#24aeb1",
                    borderRadius: "50%",
                  }}
                >
                  <AutoStoriesRounded
                    sx={{
                      color: "white",
                    }}
                  />
                </Stack>
                <Stack sx={{ width: "75%" }}>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "11pt", fontWeight: 600 }}
                  >
                    বই
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "10pt", fontWeight: 500 }}
                  >
                    নিত্ত নূতন বই
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={{
                    width: "45px",
                    height: "45px",
                    backgroundColor: "#eb3a7b",
                    borderRadius: "50%",
                  }}
                >
                  <GiLipstick
                    style={{
                      color: "white",
                    }}
                  />
                </Stack>
                <Stack sx={{ width: "75%" }}>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "11pt", fontWeight: 600 }}
                  >
                    প্রসাধনী
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "10pt", fontWeight: 500 }}
                  >
                    সাজগজের সমাহার
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={{
                    width: "45px",
                    height: "45px",
                    backgroundColor: "#00d084",
                    borderRadius: "50%",
                  }}
                >
                  <CheckroomRounded
                    sx={{
                      color: "white",
                    }}
                  />
                </Stack>
                <Stack sx={{ width: "75%" }}>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "11pt", fontWeight: 600 }}
                  >
                    পোশাক
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "10pt", fontWeight: 500 }}
                  >
                    জীবনধারা
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={{
                    width: "45px",
                    height: "45px",
                    backgroundColor: "#51acf6",
                    borderRadius: "50%",
                  }}
                >
                  <SaveAsRounded
                    sx={{
                      color: "white",
                    }}
                  />
                </Stack>
                <Stack sx={{ width: "75%" }}>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "11pt", fontWeight: 600 }}
                  >
                    ষ্টেশনারী
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "10pt", fontWeight: 500 }}
                  >
                    নিত্ত প্রয়োজনীয় পণ্য
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default BannerFooter;
