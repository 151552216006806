import {
  Edit,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const CheckoutLeft = () => {
  const initialUser = {
    isSignedIn: false,
    name: "",
    email: "",
    phoneNo: "",
    zipcode: "",
    country: "",
    district: "",
    address: "",
    fieldErrorAlert: "",
  };
  const [user, setUser] = useState(initialUser);
  const navigate = useNavigate();
  const [age, setAge] = React.useState("");
  const [country, setCountry] = React.useState("Canada");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };
  const handleCheckout = () => {
    navigate("/paymentmethod");
  };
  const handleBacktoCart = () => {
    navigate("/shoppingcart");
  };

  const handleFieldValueChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "name") {
      isFieldValid =
      /^[A-Za-z ]+$/.test(
          event.target.value
        );
    }
    if (event.target.name === "country") {
      isFieldValid =
      /([^\s])/.test(
          event.target.value
        );
    }
    if (event.target.name === "phoneNo") {
      isFieldValid =
      /^[0-9]{11,11}$/.test(
          event.target.value
        );
    }
    if (event.target.name === "zipcode") {
      isFieldValid =
      /^[0-9]{4,4}$/.test(
          event.target.value
        );
    }
    if (event.target.name === "district") {
      isFieldValid =
      /([^\s])/.test(
          event.target.value
        );
    }
    if (event.target.name === "address") {
      isFieldValid =
    /^[A-Za-z0-9 .,'!&*/+]+$/.test(
          event.target.value
        );
    }
    if (event.target.name === "email") {
      isFieldValid = /\S+@\S+\.\S+/.test(event.target.value);
    }
    

    if (isFieldValid) {
      const updateUser = user;
      updateUser[event.target.name] = event.target.value;
      updateUser.fieldErrorAlert = "";
      setUser({ ...updateUser });
    }
    if (!isFieldValid) {
      const newUser = { ...user };
      newUser.fieldErrorAlert = event.target.name;
      newUser[event.target.name] = "";
      setUser(newUser);
    }
    console.log('user:', user)
  };

  const handleSubmit =(e)=> {
    console.log("formSubmitted")
    e.preventDefault();
  }
  return (
    <Stack spacing={1} sx={{ width: {xs:'100%', md:"67%"}, padding: {xs:0, sm:"15px"} }}>
      <Paper>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ padding: "15px" }}
        >
          <Stack direction="row" spacing={1}>
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              sx={{ width: 70, height: 70 }}
            />
            <Stack justifyContent="center">
              <Typography variant="p">Jhon Michle</Typography>
              <Typography variant="p">michle@example.com</Typography>
            </Stack>
          </Stack>
          <Stack justifyContent="center">
            <Button
              size=""
              startIcon={<Edit />}
              variant="contained"
              sx={{
                bgcolor: "white",
                color: "black",
                "&:hover": {
                  color: "white",
                },
              }}
            >
              <Typography variant="caption">Edit profile</Typography>
            </Button>
          </Stack>
        </Stack>
        <Stack
          spacing={1}
          sx={{ border: "1px solid #dee2e6", padding: "15px", margin: "15px" }}
        >
          <Typography variant="h6">Shipping Address</Typography>
          <Divider />
          <FormControl action="" >
            <Box sx={{ width: "100%" }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={1} sx={{}}>
                    <Typography variant="h6" sx={{}}>
                      Name
                    </Typography>
                    <OutlinedInput sx={{ width: "100%" }} type="text" name="name" onChange={handleFieldValueChange} placeholder="" size="small" />
                    {user.fieldErrorAlert === "name" && (
                      <small style={{ color: "#CB0101" }}> *invalid name</small>
                    )}
                  </Stack>
                </Grid>
                
                
                <Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={1} sx={{}}>
                    <Typography variant="h6" sx={{}}>
                      E-mail
                    </Typography>
                    <OutlinedInput sx={{ width: "100%" }} type="email" name="email" onBlur={handleFieldValueChange}  placeholder="" size="small" />
                    {user.fieldErrorAlert === "email" && (
                      <small style={{ color: "#CB0101" }}> *invalid Email</small>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={1} sx={{}}>
                    <Typography variant="h6" sx={{}}>
                      Phone Number
                    </Typography>
                    <OutlinedInput sx={{ width: "100%" }} type="number" max='11' name="phoneNo" onBlur={handleFieldValueChange} placeholder="" size="small" />
                    {user.fieldErrorAlert === "phoneNo" && (
                      <small style={{ color: "#CB0101" }}> *invalid phone number</small>
                    )}
                  </Stack>
                </Grid>
                
                
                <Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={1} sx={{}}>
                    <Typography variant="h6" sx={{}}>
                      Zip/Postal code
                    </Typography>
                    <OutlinedInput sx={{ width: "100%" }} type="number" min='4' max='6' name="zipcode" onChange={handleFieldValueChange} placeholder="" size="small" />
                    {user.fieldErrorAlert === "phoneNo" && (
                      <small style={{ color: "#CB0101" }}> *invalid Zip/Post code</small>
                    )}
                  </Stack>
                </Grid>
                
                
                <Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={1} sx={{}}>
                    <Typography variant="h6">Country</Typography>
          
                      <Select
                        value={age}
                        onChange={handleCountryChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        size="small"
                        name="country"
                        // onChange={() => {
                        //   handleCountryChange()
                        //   handleFieldValueChange();
                        // }}
                      >
                        <MenuItem value="Canada">
                          <em>Canada</em>
                        </MenuItem>
                        <MenuItem value='Bangladesh'>Bangladesh</MenuItem>
                        <MenuItem value={20}>Texas</MenuItem>
                        <MenuItem value={30}>Canada</MenuItem>
                      </Select>
          
                  </Stack>
                </Grid>
                {country === 'Bangladesh' ? (<Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={1} sx={{}}>
                    <Typography variant="h6">District</Typography>

                      <Select
                        value={age}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        size="small"
                        name="district"
                        onChange={handleFieldValueChange}
                      >
                        <MenuItem value="">
                          <em>Dhaka</em>
                        </MenuItem>
                        <MenuItem value='Chittagong'>Chittagong</MenuItem>
                        <MenuItem value={'Rajshahi'}>Rajshahi</MenuItem>
                        <MenuItem value={30}>Barisal</MenuItem>
                      </Select>

                  </Stack>
                </Grid>) : (<Grid item xs={12} sm={6} md={6}>
                  <Stack spacing={1} sx={{}}>
                    <Typography variant="h6" sx={{}}>
                      District
                    </Typography>
                      <OutlinedInput sx={{ width: "100%" }} type="text" name="district" onChange={handleFieldValueChange} placeholder="" size="small" />
                  </Stack>
                </Grid>)}
                
                <Grid item xs={12} sm={12} md={12}>
                  <Stack spacing={1} sx={{}}>
                    <Typography variant="h6" sx={{}}>
                      Address
                    </Typography>
                    <TextareaAutosize
                      aria-label="empty textarea"
                      placeholder="Empty"
                      minRows={3}
                      style={{ width: "100%" }}
                      name="address"
                      type="text"
                      onChange={handleFieldValueChange}
                    />
                    {user.fieldErrorAlert === "address" && (
                      <small style={{ color: "#CB0101" }}> *invalid address</small>
                    )}
                  </Stack>
                </Grid>
                
              </Grid>
            </Box>
            
          </FormControl>
          
          <Stack direction="row" alignItems="center">
            <Button
              startIcon={<KeyboardArrowLeft />}
              sx={{ width: "50%" }}
              onClick={handleBacktoCart}
            >
              Back to cart
            </Button>
            {!user.fieldErrorAlert && <Button
              variant="conatined"
              
              endIcon={<KeyboardArrowRight />}
              onClick={handleCheckout}
              sx={{
                width: "50%",
                bgcolor: "black",
                color: "white",
                "&:hover": {
                  color: "black",
                  backgroundColor: "white",
                },
              }}
            >
              Proceed to Payment
            </Button>}
            {user.fieldErrorAlert && <Button variant="contained" sx={{ width: "50%",}}  disabled>Proceed to Payment</Button>}
      
            
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default CheckoutLeft;
