import { Container } from "@mui/system";
import React from "react";

const RefundPolicy = () => {
  return (
    <Container>
      <h3
        class="heading-title"
        style={{
          color: "#333333",
          fontWeight: 500,
          padding: 0,
          fontSize: "1.75em",
          marginBottom: "1em",
        }}
      >
        রিফান্ড পলিসি (Safe Refund)
      </h3>
      <p>
        ১) আপনার পেইড অর্ডারটি ক্যান্সেল হলে কিংবা গ্রাহক নিজে ক্যান্সেল করতে
        অনুরোধ করলে সম্পূর্ণ মূল্য রিফান্ড দেয়া হবে।
      </p>
      <p>
        ২) পেইড অর্ডার ডেলিভারি না হলে কিংবা ডেলিভারি হবার পূর্বেই রিটার্ন এলে
        সম্পূর্ণ মূল্য রিফান্ড দেয়া হবে। কাস্টোমার যদি নতুন করে অর্ডারটি
        ডেলিভারি নিতে চান এক্ষেত্রে নতুন করে পাঠানো হবে।
      </p>
      <p>৩) আপনার ফেরত পাঠানো পণ্যটি যাচাই-বাছাইয়ের পর রিফান্ড দেয়া হবে।</p>
      <p>
        ৪) যদি সাপ্লাইয়ের কাছে স্টক থাকে, তাহলে আগের পণ্যটি পরিবর্তন করে নতুন
        পণ্য দেওয়া হবে। যদি স্টক না থাকে, তাহলে পণ্যের জন্য আপনার পরিশোধ-কৃত
        সম্পূর্ণ টাকা রিফান্ড করা হবে।
      </p>
      <p>
        ৫) যদি পেমেন্ট মেথড হিসাবে ‘ক্যাশ অন ডেলিভারি’ সিলেক্ট করে থাকেন এবং
        আপনি যদি পণ্যটির জন্য কোন টাকা পরিশোধ না করে থাকেন, তাহলে কোনো টাকা
        রিফান্ড পাবেন না।
      </p>
      <p>
        ৬) অনলাইন পেমেন্টের ক্ষেত্রে টেকনিক্যাল ভুলের কারণে যদি বেশি টাকা কেটে
        রাখা হয়, তাহলে যথাযথ প্রমাণ প্রদানপূর্বক অতিরিক্ত অর্থ ফেরত দেওয়া হবে।
      </p>
      <p>
        ৭) অনলাইন পেমেন্টের ক্ষেত্রে গ্রাহক পণ্যের মূল্যের চেয়ে অতিরিক্ত টাকা
        পেমেন্ট করে থাকলে, অথবা পেইড অর্ডারের কোনো পণ্য যদি স্টকে না থাকার কারণে
        অর্ডার থেকে বাদ দেয়া হয়, তবে সেক্ষেত্রে মোট মূল্যের অতিরিক্ত টাকা
        গ্রাহককে রিফান্ড করে দেয়া হবে।
      </p>
      <p>
        ৮) পেইড অর্ডার প্রসেসিং স্ট্যাটাসে থাকাবস্থায় যদি কোনো পণ্য সাপ্লাইয়ারের
        থেকে না পাওয়া যায় কিংবা দ্রুততম সময়ের মধ্যে সংগ্রহ না করা যায়,
        সেক্ষেত্রে ক্রেতার সাথে আলোচনাপূর্বক উক্ত পণ্যটির টাকা রিফান্ড করে বাকি
        পণ্যগুলো পাঠিয়ে দেয়া হবে। এক্ষেত্রে ৩-৭ কার্যদিবসের মধ্যে রিফান্ড করে
        দেয়া হবে ইনশাআল্লাহ।
      </p>
      <p>
        ৯) ক্যাশব্যাক অফার চলাকালীন কোন কারণে অর্ডার ক্যান্সেল করলে
        হরেকরকমবিডি.কম কর্তৃপক্ষ আপনাকে ক্যাশব্যাকের এমাউন্টটি বাদ দিয়ে অর্ডারের
        পেমেন্ট রিফান্ড করবে।
      </p>
      <p>
        ১০) রিটার্ন ও এক্সচেঞ্জ পলিসি অনুসরণ করে নির্দিষ্ট সময়ের মধ্যে পণ্যের
        ত্রুটি প্রমাণসহ উত্থাপন করলে তবেই রিফান্ড প্রযোজ্য হবে। নির্দিষ্ট সময়ের
        পর কর্তৃপক্ষকে জানালে রিফান্ড প্রযোজ্য হবে না।
      </p>
      <p>
        ১১) রিফান্ড প্রসেস করতে সাধারণত ৭-১৫ দিন সময় লাগে। যদি এর মাঝে রিফান্ড
        না পান, তাহলে আমাদের সাথে যোগাযোগ করবেন।
      </p>
      <p>
        ১২) রিফান্ড সাধারণত আমরা মোবাইল ব্যাংকিং এর মাধ্যমে করে থাকি; যেমন:
        বিকাশ, রকেট, নগদ।
      </p>
    </Container>
  );
};

export default RefundPolicy;
