import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
import { useState } from "react";
import { brandCatApi, cosmeticBrandCatApi } from "../Api/Api";

const CosmeticsBrandList = () => {
  const [checked, setChecked] = useState([0]);
  const [cosmeticsBrandListData, setCosmeticsBrandListData] = useState([]);
  console.log("brand:", cosmeticsBrandListData);

  useEffect(() => {
    fetch(cosmeticBrandCatApi)
      .then((res) => res.json())
      .then((result) => {
        setCosmeticsBrandListData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, []);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        height: "30vh",
        overflowY: "scroll",
      }}
    >
      {cosmeticsBrandListData.map((value, index) => {
        const labelId = `checkbox-list-label-${value.pr_name}`;

        return (
          <ListItem key={value.pr_name} disablePadding>
            <ListItemButton
              sx={{ paddingY: 0 }}
              role={undefined}
              onClick={handleToggle(value.pr_name)}
              dense
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.pr_name} `} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default CosmeticsBrandList;
