import { FavoriteBorder, HighlightOff } from "@mui/icons-material";
import {
  Button,
  Container,
  Divider,
  FilledInput,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useCart } from "react-use-cart";
import { alltotalContext, totalContext } from "../../App";
import logo from "../../images/logo.png";

const CheckOutRightItem = (props) => {
    const {
        id,
        quantity,
        product_name,
        price,
        count,
        currency,
        pro_pic,
        pro_pic_2,
        category,
      } = props.data;
      const {
        isEmpty,
        items,
        cartTotal,
        updateItemQuantity,
        removeItem,
        emptyCart,
        } = useCart();
        
        // let amount = 0;
        let  individualAmount = price * quantity;
    return (
        <Box>
            <Stack direction="row" spacing={2}>
                <Box
                    component="img"
                    sx={{
                    height: 43,
                    width: 70,
                    }}
                    alt="The house from the offer."
                    src={pro_pic}
                />
                <Stack justifyContent="center">
                    <Typography variant="p">{product_name}</Typography>
                    <Typography variant="p">BDT {price} X {quantity}</Typography>
                </Stack>
           </Stack>
      </Box>
    );
};

export default CheckOutRightItem;