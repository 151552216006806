import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Container } from "@mui/material";
import Review from "./Review";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const TabDescription = (props) => {
  const { productDetails } = props;
  const { attachment } = productDetails;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="DESCRIPTION" {...a11yProps(0)} />
          <Tab label="MORE INFO" {...a11yProps(1)} />
          <Tab label="TAGS" {...a11yProps(2)} />
          <Tab label="REVIEWS" {...a11yProps(3)} />
          <Tab label="PDF" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Typography variant="p">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam vel
          enim non felis ornare pellentesque molestie sit amet lacus..
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography variant="p">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eius
          voluptate ipsam iste consequuntur rem amet, est ex aut odit
          necessitatibus totam, ut voluptatibus? In et ullam nulla amet eligendi
          eos aperiam sapiente vel placeat autem nihil quam architecto ipsam
          voluptatem odit pariatur, impedit id qui necessitatibus beatae
          doloremque optio maxime perferendis adipisci. Tempora porro quae earum
          nostrum ex minus ad harum dolorem excepturi molestiae placeat maxime,
          cupiditate doloribus! Illum, fugiat!
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Button
          variant="contained"
          sx={{ bgcolor: "black", borderRadius: 0, marginRight: "10px" }}
        >
          Cloths
        </Button>
        <Button
          variant="contained"
          sx={{ bgcolor: "black", borderRadius: 0, marginRight: "10px" }}
        >
          Books
        </Button>
        <Button
          variant="contained"
          sx={{ bgcolor: "black", borderRadius: 0, marginRight: "10px" }}
        >
          Food
        </Button>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Review />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <iframe src={attachment} width="100%" height="500px"></iframe>
      </TabPanel>
    </Container>
  );
};

export default TabDescription;
