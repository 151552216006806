import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Home, HomeOutlined, NavigateNext } from '@mui/icons-material';
import { Box } from '@mui/system';
import { Container } from '@mui/material';



const CustomBreadcrumb = (props) => {
    // const breadcrumbs = [
    //     <Link
    //       underline="hover"
    //       key="2"
    //       color="inherit"
    //       href="/material-ui/getting-started/installation/"
    //       onClick={handleClick}
    //     >
    //     <HomeOutlined fontSize="small"/>
    //     <Typography variant='p'>
    //     HOME
    //     </Typography>
        
    //     </Link>,
    //     <Typography key="3"variant='p'>
    //       Books
    //     </Typography>,
    //   ];
    
      return (
        <Container>
        <Container sx={{height: '60px', display: "flex",justifyContent: "Space-between", alignItems: "center", }}>
            <Typography variant='h6'>
            {props.topic}
            </Typography>
        <Stack spacing={2}  >
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            {props.breadcrumbs}
          </Breadcrumbs>
        </Stack>
        </Container>
        </Container>
      );
};

export default CustomBreadcrumb;