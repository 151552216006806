import { Box, Stack } from "@mui/system";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import subscription from "../../images/Subscription.jpg";
import { FormControl, OutlinedInput, Typography } from "@mui/material";
import "./NovoForm.css";

const NovoForm = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Stack
      spacing={1}
      direction={{ xs: "column", sm: "row" }}
      sx={{ marginTop: "40px" }}
    >
      <Stack sx={{ width: { xs: "100%", sm: "50%" } }}>
        <Slider {...settings}>
          <Box>
            <Box
              component="img"
              sx={{
                width: "100%",
                borderRadius: "8px",
                // maxHeight: { xs: 233, md: 167 },
                // maxWidth: { xs: 350, md: 250 },
                // display: { xs: "none", sm: "none", md: "block" },
              }}
              alt="The house from the offer."
              src={subscription}
            />
          </Box>
          <Box>
            <Box
              component="img"
              sx={{
                width: "100%",
                borderRadius: "8px",
                // maxHeight: { xs: 233, md: 167 },
                // maxWidth: { xs: 350, md: 250 },
                // display: { xs: "none", sm: "none", md: "block" },
              }}
              alt="The house from the offer."
              src={subscription}
            />
          </Box>
          <Box>
            <Box
              component="img"
              sx={{
                width: "100%",
                borderRadius: "8px",
                // maxHeight: { xs: 233, md: 167 },
                // maxWidth: { xs: 350, md: 250 },
                // display: { xs: "none", sm: "none", md: "block" },
              }}
              alt="The house from the offer."
              src={subscription}
            />
          </Box>
          <Box>
            <Box
              component="img"
              sx={{
                width: "100%",
                borderRadius: "8px",
                // maxHeight: { xs: 233, md: 167 },
                // maxWidth: { xs: 350, md: 250 },
                // display: { xs: "none", sm: "none", md: "block" },
              }}
              alt="The house from the offer."
              src={subscription}
            />
          </Box>
          <Box>
            <Box
              component="img"
              sx={{
                width: "100%",
                borderRadius: "8px",
                // maxHeight: { xs: 233, md: 167 },
                // maxWidth: { xs: 350, md: 250 },
                // display: { xs: "none", sm: "none", md: "block" },
              }}
              alt="The house from the offer."
              src={subscription}
            />
          </Box>

          <Box>
            <Box
              component="img"
              sx={{
                width: "100%",
                borderRadius: "8px",
                // maxHeight: { xs: 233, md: 167 },
                // maxWidth: { xs: 350, md: 250 },
                // display: { xs: "none", sm: "none", md: "block" },
              }}
              alt="The house from the offer."
              src={subscription}
            />
          </Box>
        </Slider>
      </Stack>
      <Stack sx={{ width: { xs: "100%", sm: "50%" } }} alignItems="center">
        <Typography variant="p">
          <strong>Send Your Writeup Here</strong>
        </Typography>
        <FormControl sx={{ width: "70%" }}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography variant="p">
                <strong>Name: </strong>
              </Typography>
              <OutlinedInput placeholder="Please enter You Name" size="small" />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="p">
                <strong>Email:</strong>
              </Typography>
              <OutlinedInput
                placeholder="Please enter You email"
                size="small"
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="p">
                <strong>Phone:</strong>{" "}
              </Typography>
              <OutlinedInput
                placeholder="Please enter You Phone No"
                size="small"
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="p">
                <strong>Attachment (doc only)</strong>
              </Typography>
              <OutlinedInput
                type="file"
                placeholder="Please enter You Name"
                size="small"
              />
            </Stack>
          </Stack>
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default NovoForm;
