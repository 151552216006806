import { Box } from "@mui/system";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import BookPage from "./BookPage";
import ProductDetails from "./ProductDetails";
import ShoppingCart from "./ShoppingCart";
import Booknow from "./Booknow";
import PaymentCheckout from "./PaymentCheckout";
import ReviewOrder from "./ReviewOrder";
import Checkout from "./Checkout";
import PrivateRoute from "../components/PrivateRoute";
import Subject from "./Subject";
import Publisher from "./Publisher";
import Writer from "./Writer";
import Cloth from "./ClothPage";
import Cosmetics from "./CosmeticsPage";
import Stationery from "./Stationery";
import ShopCategories from "./ShopCategories";
import BookFair from "./BookFair";
import PreOrder from "./PreOrder";
import PaymentMethod from "./PaymentMethod";
import NovoBhobon from "./NovoBhobon/NovoBhobon";
import NovoBhobonList from "./NovoBhobon/NovoBhobonList";
import Contact from "./Contact";
import PrivacyPolicy from "./PrivacyPolicy";
import RefundPolicy from "./RefundPolicy";
import Terms from "./Terms";
import Package from "./Package";
import GeneralBook from "./GeneralBook";
import BookFilter from "./BookFilter";
import ClothPage from "./ClothPage";
import CosmeticsPage from "./CosmeticsPage";
import GeneralPage from "./GeneralPage";

const Pages = () => {
  return (
    <Box>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/refundpolicy" element={<RefundPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/package" element={<Package />} />
        <Route path="/productdetails-:id-:type" element={<ProductDetails />} />
        <Route path="/book" element={<BookPage />} />
        <Route path="/subject" element={<Subject />} />
        <Route path="/writer" element={<Writer />} />
        <Route path="/publisher" element={<Publisher />} />
        <Route path="/clothings" element={<ClothPage />} />
        <Route path="/cosmetic" element={<CosmeticsPage />} />
        <Route path="/stationary" element={<Stationery />} />
        <Route path="/general" element={<GeneralPage />} />
        <Route path="/generalbook" element={<GeneralBook />} />
        <Route path="/bookfilter" element={<BookFilter />} />
        <Route path="/shoppingcart" element={<ShoppingCart />} />
        <Route path="/shoppingcart:id:quantity" element={<ShoppingCart />} />
        <Route path="/booknow" element={<Booknow />} />
        <Route path="/paymentcheckout" element={<PaymentCheckout />} />
        <Route path="/revieworder" element={<ReviewOrder />} />
        <Route path="/paymentmethod" element={<PaymentMethod />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/shop-categories" element={<ShopCategories />} />
        <Route path="/bookfair" element={<BookFair />} />
        <Route path="/preorder" element={<PreOrder />} />
        <Route path="/novo" element={<NovoBhobon />} />
        <Route path="/novol" element={<NovoBhobonList />} />
      </Routes>
    </Box>
  );
};

export default Pages;
