import { Container } from "@mui/system";
import React from "react";

const Terms = () => {
  return (
    <Container>
      <h4
        class="heading-title"
        style={{
          color: "#222",
          fontWeight: 500,
          textAlign: "center",
          padding: 0,
          fontSize: "1.75em",
          marginBottom: "1em",
        }}
      >
        শর্তাবলী (Terms of Use)
      </h4>
      <p>
        হরেকরকমবিডি.কম আপনাকে স্বাগতম। হরেকরকমবিডি.কম (info@horekrokombd.com)
        ব্যাবহার করতে হলে অথবা এর মাধ্যমে কোন তথ্য অথবা সেবা পেতে হলে নিচের শর্ত
        গুলি মেনে নিতে হবে &#8211;
      </p>
      <p>
        ১) এই শর্তাদিতে, &#8220;আপনি&#8221;, &#8220;ব্যবহারকারী&#8221; এর
        রেফারেন্সের অর্থ সর্বশেষ ব্যবহারকারী / গ্রাহককে বোঝায় যিনি ওয়েবসাইটটি,
        এর বিষয়বস্তু এবং ওয়েবসাইটের মাধ্যমে প্রদত্ত সেবাগুলি ব্যবহার করেন।
        &#8220;ওয়েবসাইট&#8221;, &#8220;হরেকরকমবিডি.কম &#8221;,
        &#8220;হরেকরকমবিডি.কম ডট কম&#8221;, &#8220;আমরা&#8221;,এবং
        &#8220;আমাদের&#8221; রেফারেন্সের অর্থ ওয়েবসাইট এবং / অথবা ওয়াফি
        সল্যুশনস।
      </p>
      <p>
        ২) আমরা যে কোন সময়ে এই শর্তাবলী পরিবর্তন করার অধিকার রাখি। যা
        ওয়েবসাইটে প্রকাশ করা হলে সাথে সাথে কার্যকর হবে। আপনার এই ওয়েবসাইট
        ব্যবহার অব্যাহত থাকার মানে হচ্ছে আপনি সংশোধিত শর্তাদি মেনে নিয়েছেন।
      </p>
      <p>৩) ওয়েবসাইটটি ওয়াফি সল্যুশনস দ্বারা পরিচালিত।</p>
      <p>
        ৪) হরেকরকমবিডি.কম বিভিন্ন ধরনের পণ্য বিক্রি করে থাকে। ওয়েব সাইটে
        প্রদর্শিত সকল পণ্য হরেকরকমবিডি.কম এর স্টকে থাকে না । আপনার অর্ডার এর কোন
        পণ্য স্টকে না থাকলে তা প্রকাশক/উৎপাদক/সরবরাহকারী এর কাছ থেকে সংগ্রহ করে
        আপনার কাছে পাঠানো হবে। যদি কোন পণ্য স্টকে এবং প্রকাশক/উৎপাদক/সরবরাহকারী
        এর কাছে না থাকে তাহলে তা হরেকরকমবিডি.কম আপনাকে সরবরাহ করতে পারবে না।
      </p>
      <p>
        ৫) হরেকরকমবিডি.কম পণ্যের বিবরণ সঠিক ভাবে দেওয়ার যথাসম্ভব চেষ্টা করে।
        যেহেতু হরেকরকমবিডি.কম এই পণ্যগুলি নিজে উৎপাদন করে না তাই পণ্যের বিবরণের
        জন্য প্রকাশক/উৎপাদক/সরবরাহকারী এর উপর নির্ভর করতে হয়। তাই কোন পণ্যের
        বিবরণ যদি ওয়েব সাইটের বিবরণের সাথে না মিলে তাহলে তার একমাত্র সমাধান
        হচ্ছে হরেকরকমবিডি.কম এর কাছে অব্যবহৃত অবস্থায় পণ্যটি ফেরত দেওয়া।
      </p>
      <p>
        ৬) আপনার অর্ডার বা ফরমায়েশ আমাদের কাছে একটি প্রস্তাব &#8211; এটি কোন
        বিক্রয় চুক্তি নয়। আপনার অর্ডার প্রাপ্তির পর আপনাকে একটি অর্ডার প্রাপ্তির
        নিশ্চিতকরণ ইমেইল এবং / অথবা এসএমএস পাঠায়। এই ইমেইল অথবা এসএমএস কোন
        বিক্রয় চুক্তি নয়। বিক্রয় চুক্তি তখনই সম্পন্ন হবে যখন আপনার অর্ডার এর
        পণ্য আপনার কাছে হস্তান্তর করা হবে।
      </p>
      <p>
        ৭) আপনার অর্ডারের পণ্য আপনার কাছে হস্তান্তরের আগে যে কোন সময়ে আপনি
        অর্ডারটি বাতিল করতে পারেন।
      </p>
      <p>
        ৮) হরেকরকমবিডি.কম সাধারণত কোন সুনির্দিষ্ট কারণ ছাড়া অর্ডার বাতিল করে না।
        তবে হরেকরকমবিডি.কম যে কোন সময় কোন কারণ দেখানো ব্যতীত অর্ডার বাতিল করার
        সম্পূর্ণ অধিকার সংরক্ষণ করে।
      </p>
    </Container>
  );
};

export default Terms;
