import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Square } from '@mui/icons-material';

const ColorsList = () => {
    return (
        <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Square sx={{color: 'red'}}/>
            </ListItemIcon>
            <ListItemText primary="Red" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Square sx={{color: 'green'}}/>
            </ListItemIcon>
            <ListItemText primary="Green" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Square sx={{color: 'yellow'}}/>
            </ListItemIcon>
            <ListItemText primary="Yellow" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Square sx={{color: 'black'}}/>
            </ListItemIcon>
            <ListItemText primary="Black" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Square sx={{color: 'white'}}/>
            </ListItemIcon>
            <ListItemText primary="White" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Square sx={{color: 'blue'}}/>
            </ListItemIcon>
            <ListItemText primary="Blue" />
          </ListItemButton>
        </ListItem>
        
      </List>
    );
};

export default ColorsList;