import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CommentIcon from "@mui/icons-material/Comment";
import IconButton from "@mui/material/IconButton";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Box, Stack } from "@mui/system";
import { KeyboardArrowDown } from "@mui/icons-material";
import "./CategoryList.css";
import { subCategoriesApi } from "../Api/Api";

const SubCategoryList = (props) => {
  const focus = useRef("");
  const [toggle, setToggle] = useState(true);
  const [categoryHeight, setCategoryHeight] = useState("");
  const [subCategoryListData, setSubCategoryListData] = useState([]);
  console.log("subCategoryListDvvatafdd:", subCategoryListData);

  useEffect(() => {
    fetch(subCategoriesApi)
      .then((res) => res.json())
      .then((result) => {
        setSubCategoryListData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, []);
  useEffect(() => {
    handleCategoryHeight(focus.current.offsetHeight);
  }, [subCategoryListData[0]]);

  const handleCategoryHeight = (value) => {
    setCategoryHeight(value);
  };
  const handleToggle = (event) => {
    setToggle(!toggle);
  };
  console.log("categoriCategoryHeight:", categoryHeight);

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "10px" }}
      >
        <Typography variant="p" sx={{ fontSize: "13pt", fontWeight: 600 }}>
          সাব ক্যাটাগরি
        </Typography>
        <IconButton
          size="small"
          onClick={() => {
            handleToggle();
          }}
        >
          <KeyboardArrowDown
            fontSize="medium"
            color="black"
            sx={{
              transform: toggle === true ? "rotate(-180deg)" : "",
              transition: "transform 1s",
            }}
          />
        </IconButton>
      </Stack>
      <Box
        className={toggle === false ? "lowCategoryHeight" : ""}
        sx={{
          height:
            categoryHeight && toggle ? `${categoryHeight}px !important` : "0px",
          overflow: "hidden",
          transition: "height 1s",
        }}
      >
        <List sx={{ width: "100%", bgcolor: "background.paper" }} ref={focus}>
          {subCategoryListData.map((value) => (
            <ListItem
              key={value.title}
              disableGutters
              sx={{ padding: 0, cursor: "pointer" }}
              // secondaryAction={
              //   <IconButton
              //     aria-label="comment"
              //     sx={{
              //       bgcolor: "#60C2A1",
              //       color: "white",
              //       marginRight: "9px",
              //     }}
              //   >
              //     <Typography variant="p" sx={{ fontSize: "6pt" }}>
              //       {value.category_listing}
              //     </Typography>
              //   </IconButton>
              // }
            >
              <ListItemText
                primary={`${value.subcat_bn}`}
                fontSize="6pt"
                size="small"
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default SubCategoryList;
