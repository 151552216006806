import {
  AddShoppingCart,
  FavoriteBorder,
  HomeOutlined,
  PictureAsPdf,
  ShoppingBasket,
  Square,
  Star,
  StarHalf,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Rating,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import { Container, Stack } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import CustomBreadcrumb from "../components/CustomBreadcrumb";
import BookOverview from "../components/HomePageComponents/BookSlider/BookOverview";
import ClothOverview from "../components/HomePageComponents/BookSlider/ClothOverview";
import CosmeticOverview from "../components/HomePageComponents/BookSlider/CosmeticOverview";
import TabDescription from "../components/ProductDetailsComponents/TabDescription";
import SelectForm from "../components/SelectForm";

const ProductDetails = () => {
  let params = useParams();
  console.log("paradfdfms:", params);
  const [productDetails, setProductDetails] = useState({});
  const [clickedPic, setClickedPic] = useState("");
  // useEffect(() => {
  //   fetch("https:/horekrokombd.comapi/web_api/product_details_book.php", {

  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       let filtered = result.filter(
  //         (element) => element.product_refer === params.id
  //       );
  //       console.log("filtered:", filtered);
  //       setProductDetails(filtered[0]);
  //       setClickedPic(result[0].pro_pic);
  //     });
  // }, []);

  console.log("detaixcxcxls", productDetails);

  useEffect(() => {
    const formData = new FormData();
    formData.append("refer", params.id);
    formData.append("action", "fetch");

    if (params.type === "book") {
      fetch("https:/horekrokombd.com/api/web_api/product_details_book.php", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          setProductDetails(result[0]);
        })
        .catch((error) => console.log("FetchUserApiError=> ", error));
    } else if (params.type === "Dress") {
      fetch("https:/horekrokombd.com/api/web_api/product_details_dress.php", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          setProductDetails(result[0]);
        })
        .catch((error) => console.log("FetchUserApiError=> ", error));
    } else if (params.type === "Cosmetics") {
      fetch(
        "https:/horekrokombd.com/api/web_api/product_details_cosmetics.php",
        {
          method: "POST",
          body: formData,
        }
      )
        .then((res) => res.json())
        .then((result) => {
          setProductDetails(result[0]);
        })
        .catch((error) => console.log("FetchUserApiError=> ", error));
    }
  }, []);
  console.log("productDetails in pd:", productDetails);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      // onClick={handleClick}
      style={{ textDecoration: "none" }}
    >
      <HomeOutlined
        fontSize="small"
        sx={{ color: "black", marginBottom: "-4px", marginRight: "10px" }}
      />
      <Typography variant="p" color="text.primary">
        Home
      </Typography>
    </Link>,
    <Typography key="3" variant="p" color="text.primary">
      Shop
    </Typography>,
    <Typography key="3" variant="p" color="text.primary">
      Product Details
    </Typography>,
  ];

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Box>
      <Box
        sx={{ bgcolor: "#f8f9fa", border: "1px solid #E6E6DF", marginTop: -1 }}
      >
        <CustomBreadcrumb
          breadcrumbs={breadcrumbs}
          topic={productDetails.product_name}
        />
      </Box>
      {productDetails.ca_type === "BOOK" && (
        <BookOverview productDetails={productDetails} />
      )}
      {productDetails.ca_type === "CLOTH" && (
        <ClothOverview productDetails={productDetails} />
      )}
      {productDetails.ca_type === "COSMETIC" && (
        <CosmeticOverview productDetails={productDetails} />
      )}

      <TabDescription productDetails={productDetails} />
    </Box>
  );
};

export default ProductDetails;
