import React, { useContext, useEffect, useState } from "react";
import {
  Add,
  AddCircle,
  AddShoppingCart,
  Close,
  FavoriteBorder,
  HomeOutlined,
  PictureAsPdf,
  RemoveCircle,
  ShoppingBasket,
  Square,
  Star,
  StarHalf,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Rating,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import { Container, Stack } from "@mui/system";
import { useCart } from "react-use-cart";
import { Navigate, useNavigate } from "react-router-dom";
import { PDstoragedContext, PDstoragedCountContext } from "../../../App";
import styled from "styled-components";
const ClothOverview = (props) => {
  const { productDetails } = props;
  const {
    id,
    product_refer,
    product_name,
    price,
    currency,
    pro_pic,
    pro_pic_2,
    brand,
    category,
    sub_category,
    product_type,
    dsize,
    dcolor,
  } = productDetails;

  let DsizeList = dsize.split(",");
  let DcolorList = dcolor.split(",");
  const [DPprice, setDPprice] = React.useState(0);
  const [actualPrice, setActualPrice] = React.useState(0);
  const [basePrice, setBasePrice] = React.useState(0);
  const [DPquantity, setDPquantity] = React.useState(1);
  const [age, setAge] = React.useState("");
  const [size, setSize] = React.useState("");
  const [color, setColor] = React.useState("");
  const [activeTab, setActiveTab] = React.useState("");
  const [clickedPic, setClickedPic] = useState("");
  const { addItem, updateItemQuantity } = useCart();
  const navigate = useNavigate();

  const addToCart = (data) => {
    console.log(data);
    addItem(data);
    console.log("data passed", data);
  };
  useEffect(() => {
    setClickedPic(pro_pic);
  }, []);

  useEffect(() => {
    let newTotal = parseFloat(price);
    newTotal = newTotal * DPquantity;
    setActualPrice(newTotal);
  }, [DPquantity]);

  useEffect(() => {
    let newTotal = parseFloat(price) - (parseFloat(price) * 10) / 100;
    newTotal = newTotal * DPquantity;
    setDPprice(newTotal);
  }, [DPquantity]);
  useEffect(() => {
    let newTotal = parseFloat(price) - (parseFloat(price) * 10) / 100;
    newTotal = newTotal * DPquantity;
    setBasePrice(newTotal);
  }, []);

  const handleQuantity = (event) => {
    setDPquantity(event.target.value);
  };

  const handleChange = (event) => {
    setSize(event.target.value);
  };

  const handlePDstorage = (data, quantity, basePrice, size, color) => {
    const newData = data;
    newData.price = basePrice;
    newData.size = size;
    newData.color = color;
    addItem(newData);
    // updateItemQuantity(id, quantity)
    navigate("/shoppingCart");
  };

  const reduceQuantity = () => {
    let newCount = DPquantity;
    newCount--;
    setDPquantity(newCount);
  };
  const addQuantity = () => {
    let newCount = DPquantity;
    newCount++;
    setDPquantity(newCount);
  };
  console.log("selected size", size);
  return (
    <Container sx={{ marginTop: "2%", width: "100%", height: "100%" }}>
      <ColorWrapper>
        <Toolbar>
          <Box sx={{ width: "50%", boxSizing: "border-box" }}>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ height: "85%", marginBottom: "4%" }}
            >
              <Box
                component="img"
                sx={{
                  padding: "15px",
                  // width: { xs: '50%', md: '92%'},
                  height: { xs: "13%", md: "70%" },
                  margin: "3px",
                  border: "1px solid #E6E6DF",
                }}
                alt="The house from the offer."
                src={clickedPic}
              />
            </Stack>

            <Stack
              direction="row"
              sx={{ height: "15%", justifyContent: "center" }}
            >
              <Box
                component="img"
                sx={{
                  width: { xs: "10%", md: "14%" },
                  height: { xs: "13%", md: "19%" },
                  margin: "3px",
                  padding: "8px",
                  border: "1px solid #E6E6DF",
                }}
                alt="The house from the offer."
                src={pro_pic}
                onClick={(e) => {
                  setClickedPic(e.target.src);
                }}
              />
              <Box
                component="img"
                sx={{
                  width: { xs: "10%", md: "14%" },
                  height: { xs: "13%", md: "19%" },
                  margin: "3px",
                  padding: "8px",
                  border: "1px solid #E6E6DF",
                }}
                alt="The house from the offer."
                src={pro_pic_2}
                onClick={(e) => {
                  setClickedPic(e.target.src);
                }}
              />
              <Box
                component="img"
                sx={{
                  width: { xs: "10%", md: "14%" },
                  height: { xs: "13%", md: "19%" },
                  margin: "3px",
                  padding: "8px",
                  border: "1px solid #E6E6DF",
                }}
                alt="The house from the offer."
                src={pro_pic}
                onClick={(e) => {
                  setClickedPic(e.target.src);
                }}
              />
              <Box
                component="img"
                sx={{
                  width: { xs: "10%", md: "14%" },
                  height: { xs: "13%", md: "19%" },
                  margin: "3px",
                  padding: "8px",
                  border: "1px solid #E6E6DF",
                }}
                alt="The house from the offer."
                src={pro_pic_2}
                onClick={(e) => {
                  setClickedPic(e.target.src);
                }}
              />
            </Stack>
          </Box>
          <Box sx={{ width: "50%", padding: "2%", height: "420px" }}>
            <Stack spacing={1} justifyContent="flex-start">
              <Typography variant="h5">
                {productDetails.product_name}
              </Typography>
              {/* <Stack direction='row'>
                            <Rating name="read-only" value={4} readOnly />
                            <Typography variant='p' sx={{ display: 'inline' }}>(24 Ratings)</Typography>
                        </Stack> */}

              <Stack>
                <Typography>Brand : {brand}</Typography>
                <Typography>Category : {category}</Typography>
                <Typography>Item : {sub_category}</Typography>
                <Typography>Fabrics : {product_type}</Typography>
              </Stack>
              {/* <Typography variant='caption'>
                            Description:
                        </Typography>
                        <Stack direction='row' spacing={2}>
                            <Typography variant='caption'>language: English,</Typography>
                            <Typography variant='caption'>Published_Date: </Typography>
                        
                        </Stack> */}
              <Stack
                alignItems="center"
                justifyContent="left"
                sx={{ width: "50%" }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="left"
                  sx={{ width: "100%" }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: "30%" }}
                  >
                    <Typography variant="p" sx={{ fontWeight: 600 }}>
                      Size{" "}
                    </Typography>
                    <FormControl
                      sx={{ maxWidth: 100, minWidth: 70 }}
                      size="small"
                    >
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={size}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {DsizeList.map((element) => (
                          <MenuItem value={element}>{element}</MenuItem>
                        ))}
                        {/* <MenuItem value={"s"}>s</MenuItem>
                        <MenuItem value={"m"}>m</MenuItem>
                        <MenuItem value={"l"}>l</MenuItem>
                        <MenuItem value={"xs"}>xs</MenuItem>
                        <MenuItem value={"xl"}>xl</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: "40%" }}
                  >
                    <Typography variant="p" sx={{ fontWeight: 600 }}>
                      Colors{" "}
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      {DcolorList.map((element) => (
                        <Square
                          sx={{ color: element, cursor: "pointer" }}
                          className={activeTab === element ? "active" : ""}
                          onClick={() => {
                            setActiveTab(element);
                            setColor(element);
                          }}
                        />
                      ))}

                      {/* <Square
                        sx={{ color: "green", cursor: "pointer" }}
                        className={activeTab === "green" ? "active" : ""}
                        onClick={() => {
                          setActiveTab("green");
                          setColor("green");
                        }}
                      />
                      <Square
                        sx={{ color: "blue", cursor: "pointer" }}
                        className={activeTab === "blue" ? "active" : ""}
                        onClick={() => {
                          setActiveTab("blue");
                          setColor("blue");
                        }}
                      />
                      <Square
                        sx={{ color: "yellow", cursor: "pointer" }}
                        className={activeTab === "yellow" ? "active" : ""}
                        onClick={() => {
                          setActiveTab("yellow");
                          setColor("yellow");
                        }}
                      /> */}
                    </Stack>
                  </Stack>
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{}}
                >
                  <Typography variant="p" sx={{ fontWeight: 600 }}>
                    Quantity:{" "}
                  </Typography>
                  <Stack
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="left"
                  >
                    <IconButton
                      color="primary"
                      variant="contained"
                      onClick={reduceQuantity}
                      className="ms-2"
                      sx={{ marginLeft: "20px" }}
                    >
                      <RemoveCircle />
                    </IconButton>

                    <span
                      style={{
                        color: "#000",
                        padding: "2px 14px",
                        border: "3px solid #777",
                        borderRadius: "10px",
                      }}
                    >
                      {DPquantity}
                    </span>

                    <IconButton
                      color="primary"
                      variant="contained"
                      onClick={addQuantity}
                      className="ms-2"
                    >
                      <AddCircle />
                    </IconButton>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography
                  variant="p"
                  sx={{
                    display: "inline",
                    textDecoration: "line-through",
                    fontWeight: 600,
                  }}
                >
                  {actualPrice} {currency}{" "}
                </Typography>
                <Typography
                  variant="p"
                  sx={{ display: "inline", fontWeight: 600 }}
                >
                  {DPprice} {currency}
                </Typography>
              </Stack>
              {/* <Button variant="contained" startIcon={<PictureAsPdf />}   sx={{ bgcolor: 'black', color: 'white', width: '74.3%' }}>Read Some</Button> */}

              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ paddingTop: "2%" }}
              >
                <Button
                  variant="contained"
                  startIcon={<AddShoppingCart />}
                  sx={{
                    width: "36%",
                    bgcolor: "tomato",
                    color: "black",
                    "&:hover": { color: "white" },
                  }}
                  onClick={() => addToCart(productDetails)}
                >
                  ADD TO CART
                </Button>
                <Button
                  variant="contained"
                  startIcon={<ShoppingBasket />}
                  sx={{
                    bgcolor: "lightcoral",
                    color: "black",
                    "&:hover": { color: "white" },
                  }}
                  onClick={() => {
                    handlePDstorage(
                      productDetails,
                      DPquantity,
                      basePrice,
                      size,
                      color
                    );
                  }}
                >
                  Direct Purchase
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Toolbar>
        <Close
          onClick={props.handleClose}
          sx={{ position: "absolute", top: 10, right: 10, cursor: "pointer" }}
        />
      </ColorWrapper>
    </Container>
  );
};

export default ClothOverview;

const ColorWrapper = styled.div`
  .active {
    border: 2px solid grey;
  }
`;
