import { Button, Grid, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import profile from "../../images/profile.webp";
import subscription from "../../images/Subscription.jpg";
import "./novobhoban.css";
import NovoBhobonList from "./NovoBhobonList";
import NovoForm from "./NovoForm";
import Profile from "./Profile";
import Articles from "./Articles";
import EditorialProfile from "./EditorialProfile";
import { ArrowRightAlt } from "@mui/icons-material";

const NovoBhobon = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Container>
      <Button variant="outlined" size="small" sx={{ marginTop: "1%" }}>
        Quick View Modal
      </Button>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginTop: "2%", marginBottom: "2%" }}
      >
        <Stack justifyContent="center" alignItems="left">
          <Typography variant="p" sx={{ fontSize: "20pt", fontWeight: 700 }}>
            Nodi Songha
          </Typography>
          <Typography variant="p" sx={{ fontSize: "14pt" }}>
            Publish Date: 20 march 2021
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems="left"
          sx={{ marginTop: "2%" }}
        >
          <Button variant="outlined" size="small">
            Hardcopy Purchase
          </Button>
          <Button variant="contained" size="small">
            e-Purchase
          </Button>
        </Stack>
      </Stack>

      <Grid container spacing={{ xs: 5, md: 3 }} sx={{ marginTop: "2%" }}>
        <Grid item xs>
          <Articles />
          {/* <Stack justifyContent="flex-end" sx={{ height: "90%" }}>
            <Typography
              sx={{
                fontSize: "16pt",
                bgcolor: "white",
                fontWeight: 700,
                textAlign: "center",
                paddingTop: "17%",
                borderTopRightRadius: "8px",
                borderTopLeftRadius: "8px",
              }}
            >
              Celebration
            </Typography>
            <Stack
              sx={{
                bgcolor: "tomato",
                height: "78%",
                borderRadius: "8px",
                // borderBottomRightRadius: "8px",
                // borderBottomLeftRadius: "8px",
              }}
              justifyContent="center"
              alignItems="center"
            >
              <Stack
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{
                  bgcolor: "white",
                  width: "70%",
                  padding: "2%",
                  height: "auto",
                  borderRadius: "5px",
                }}
              >
                <Button variant="outlined" size="small" sx={{ width: "100%" }}>
                  e-Purchase
                </Button>
                <Button variant="outlined" size="small" sx={{ width: "100%" }}>
                  e-Purchase
                </Button>
                <Button variant="outlined" size="small" sx={{ width: "100%" }}>
                  e-Purchase
                </Button>
                <Button variant="outlined" size="small" sx={{ width: "100%" }}>
                  e-Purchase
                </Button>
                <Button variant="outlined" size="small" sx={{ width: "100%" }}>
                  e-Purchase
                </Button>
              </Stack>
            </Stack>
          </Stack> */}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ position: "", marginTop: { xs: "8%", md: "2%" } }}
        >
          <Box className="carousel1" sx={{}}>
            <Slider {...settings} sx={{ padding: 0 }}>
              <Box sx={{ position: "relative" }} className="c1">
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                    // borderRadius: "8px",
                    // maxHeight: { xs: 233, md: 167 },
                    // maxWidth: { xs: 350, md: 250 },
                    // display: { xs: "none", sm: "none", md: "block" },
                  }}
                  alt="The house from the offer."
                  src={subscription}
                />
                <Box
                  className="c1Black"
                  sx={{
                    padding: "31.5% 50%",
                    bgcolor: "rgba(0,0,0,.4)",
                    // borderRadius: "8px",
                    position: "absolute",
                    overflow: "hidden",
                    top: "99%",
                    opacity: 0,
                  }}
                ></Box>
                <Button
                  className="c1btn"
                  variant="contained"
                  sx={{
                    bgcolor: "#c5460a",
                    color: "white",
                    position: "absolute",
                    top: "100%",
                    left: "7%",
                    opacity: 0,
                  }}
                  endIcon={<ArrowRightAlt />}
                >
                  Explore
                </Button>
                <Box
                  className="c1Details"
                  sx={{ position: "absolute", top: "65%", left: "7%" }}
                >
                  <Typography
                    sx={{
                      // position: "absolute",
                      // top: "65%",
                      // left: "7%",
                      fontSize: "17pt",
                      fontWeight: 700,
                      color: "white",
                    }}
                  >
                    Video & Animation
                  </Typography>
                  <Typography
                    sx={{
                      // position: "absolute",
                      // top: "80%",
                      // left: "7%",
                      fontSize: "12pt",
                      fontWeight: 500,
                      color: "white",
                    }}
                  >
                    7 Listings
                  </Typography>
                </Box>
              </Box>
              {/* <Box sx={{ position: "relative" }}>
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                    borderRadius: "8px",
                    // maxHeight: { xs: 233, md: 167 },
                    // maxWidth: { xs: 350, md: 250 },
                    // display: { xs: "none", sm: "none", md: "block" },
                  }}
                  alt="The house from the offer."
                  src={subscription}
                />
                <Box
                  sx={{
                    padding: "31.5% 50%",
                    bgcolor: "rgba(0,0,0,.4)",
                    borderRadius: "8px",
                    position: "absolute",
                    top: 0,
                  }}
                ></Box>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#c5460a",
                    color: "white",
                    position: "absolute",
                    top: "40%",
                    left: "7%",
                  }}
                >
                  Nature
                </Button>
                <Typography
                  sx={{
                    position: "absolute",
                    top: "58%",
                    left: "7%",
                    fontSize: "17pt",
                    fontWeight: 700,
                    color: "white",
                  }}
                >
                  Master The Art Of Nature With These 7 Tips
                </Typography>
                <Typography
                  sx={{
                    position: "absolute",
                    top: "70%",
                    left: "7%",
                    fontSize: "12pt",
                    fontWeight: 500,
                    color: "white",
                  }}
                >
                  Katen Doe | August 23, 2022
                </Typography>
              </Box> */}
              {/* <Box>
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                    borderRadius: "8px",
                    // maxHeight: { xs: 233, md: 167 },
                    // maxWidth: { xs: 350, md: 250 },
                    // display: { xs: "none", sm: "none", md: "block" },
                  }}
                  alt="The house from the offer."
                  src={subscription}
                />
              </Box>
              <Box>
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                    borderRadius: "8px",
                    // maxHeight: { xs: 233, md: 167 },
                    // maxWidth: { xs: 350, md: 250 },
                    // display: { xs: "none", sm: "none", md: "block" },
                  }}
                  alt="The house from the offer."
                  src={subscription}
                />
              </Box>
              <Box>
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                    borderRadius: "8px",
                    // maxHeight: { xs: 233, md: 167 },
                    // maxWidth: { xs: 350, md: 250 },
                    // display: { xs: "none", sm: "none", md: "block" },
                  }}
                  alt="The house from the offer."
                  src={subscription}
                />
              </Box>
              <Box>
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                    borderRadius: "8px",
                    // maxHeight: { xs: 233, md: 167 },
                    // maxWidth: { xs: 350, md: 250 },
                    // display: { xs: "none", sm: "none", md: "block" },
                  }}
                  alt="The house from the offer."
                  src={subscription}
                />
              </Box>

              <Box>
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                    borderRadius: "8px",
                    // maxHeight: { xs: 233, md: 167 },
                    // maxWidth: { xs: 350, md: 250 },
                    // display: { xs: "none", sm: "none", md: "block" },
                  }}
                  alt="The house from the offer."
                  src={subscription}
                />
              </Box> */}
            </Slider>
          </Box>
        </Grid>
        <Grid item xs>
          <Profile />
          {/* <EditorialProfile /> */}
        </Grid>
      </Grid>

      <Button variant="outlined" size="small" sx={{ marginTop: "2%" }}>
        About Magazine
      </Button>

      <Grid container spacing={3} sx={{ marginTop: "2%" }}>
        <Grid item xs sx={{ border: "1px solid grey" }}>
          <Stack
            spacing={1}
            justifyContent="center"
            alignItems="center"
          ></Stack>
        </Grid>
        <Grid item xs={9} sm={9} md={6}>
          <Stack justifyContent="center" alignItems="left">
            <Typography variant="p" sx={{ fontSize: "20pt", fontWeight: 700 }}>
              Nodi Songha
            </Typography>
            <Typography variant="p" sx={{ fontSize: "14pt" }}>
              Total Page:
            </Typography>
            <Typography variant="p" sx={{ fontSize: "14pt" }}>
              Theme:
            </Typography>
            <Typography variant="p" sx={{ fontSize: "14pt" }}>
              Publication Date:
            </Typography>
            <Typography variant="p" sx={{ fontSize: "14pt" }}>
              Type: Full PaperBack/e-Magazine
            </Typography>
            <Typography variant="p" sx={{ fontSize: "14pt" }}>
              Quantity:{" "}
              <span
                style={{ "background-color": "lightgreen", padding: "0 1%" }}
              >
                1
              </span>{" "}
              Pcs
            </Typography>
            <Typography variant="p" sx={{ fontSize: "14pt" }}>
              PaperBack Price: 170 BDT{" "}
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="left"
              alignItems="center"
              sx={{ marginTop: "2%" }}
            >
              <Button variant="outlined" size="small">
                Hardcopy Purchase
              </Button>
              <Button variant="contained" size="small">
                e-Purchase
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs>
          <Stack justifyContent="start" alignItems="center"></Stack>
        </Grid>
      </Grid>

      {/* <Box
        sx={{
          marginTop: "2%",
          width: "50%",
          padding: "1%",
          bgcolor: "#3BCCA4",
          borderRadius: "8px",
        }}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{}}
        >
          <Grid item xs={6}>
            <Stack justifyContent="center" alignItems="left">
              <Typography
                variant="p"
                sx={{ fontSize: "16pt", fontWeight: 700 }}
              >
                Subscription Price
              </Typography>
              <Typography
                variant="caption"
                sx={{ fontSize: "12pt", fontWeight: 700 }}
              >
                $100
              </Typography>
              <Button
                variant="contained"
                size="small"
                sx={{ bgcolor: "lightcoral", width: "50%", marginTop: "2%" }}
              >
                <Typography variant="caption">Subscribe Now</Typography>
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack justifyContent="center" alignItems="center">
              <Box
                component="img"
                sx={{
                  width: 150,
                  borderRadius: "8px",
                  // maxHeight: { xs: 233, md: 167 },
                  // maxWidth: { xs: 350, md: 250 },
                  // display: { xs: "none", sm: "none", md: "block" },
                }}
                alt="The house from the offer."
                src={subscription}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box> */}

      <Grid container rowSpacing={2} columnSpacing={6} sx={{ marginTop: "2%" }}>
        <Grid item xs={12} md={6}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ padding: "1%", bgcolor: "#3BCCA4", borderRadius: "8px" }}
          >
            <Grid item xs={6}>
              <Stack justifyContent="center" alignItems="left">
                <Typography
                  variant="p"
                  sx={{ fontSize: "16pt", fontWeight: 700 }}
                >
                  Subscription Price
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "12pt", fontWeight: 700 }}
                >
                  $100
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ bgcolor: "lightcoral", width: "50%", marginTop: "2%" }}
                >
                  <Typography variant="caption">Subscribe Now</Typography>
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack justifyContent="center" alignItems="center">
                <Box
                  component="img"
                  sx={{
                    width: 150,
                    borderRadius: "8px",
                    // maxHeight: { xs: 233, md: 167 },
                    // maxWidth: { xs: 350, md: 250 },
                    // display: { xs: "none", sm: "none", md: "block" },
                  }}
                  alt="The house from the offer."
                  src={subscription}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ padding: "1%", bgcolor: "#3BCCA4", borderRadius: "8px" }}
          >
            <Grid item xs={6}>
              <Stack justifyContent="center" alignItems="left">
                <Typography
                  variant="p"
                  sx={{ fontSize: "16pt", fontWeight: 700 }}
                >
                  Subscription Price
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "12pt", fontWeight: 700 }}
                >
                  $100
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ bgcolor: "lightcoral", width: "50%", marginTop: "2%" }}
                >
                  <Typography variant="caption">Subscribe Now</Typography>
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack justifyContent="center" alignItems="center">
                <Box
                  component="img"
                  sx={{
                    width: 150,
                    borderRadius: "8px",
                    // maxHeight: { xs: 233, md: 167 },
                    // maxWidth: { xs: 350, md: 250 },
                    // display: { xs: "none", sm: "none", md: "block" },
                  }}
                  alt="The house from the offer."
                  src={subscription}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <NovoBhobonList />
      <NovoForm />
    </Container>
  );
};

export default NovoBhobon;
