import {
  Box,
  Container,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useState } from "react";

const Contact = () => {
  const [userType, setUserType] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  return (
    <Container>
      <Stack mt={4}>
        <Typography variant="p" sx={{ fontSize: "20pt", fontWeight: 600 }}>
          Send Us Following Information
        </Typography>
        <Box sx={{ paddingY: "5%", paddingX: "10%" }}>
          <Grid container spacing={2} sx={{ bgcolor: "" }}>
            <Grid item xs={6}>
              <Box>xs=8</Box>
            </Grid>
            <Grid item xs={6}>
              <Stack
                spacing={2}
                sx={{ paddingY: "10%", paddingX: "20%", bgcolor: "" }}
              >
                <Stack spacing={0.5}>
                  <Typography variant="p" sx={{ fontSize: "12pt" }}>
                    Full Name
                  </Typography>

                  <OutlinedInput
                    //   defaultValue="react-bootstrap"
                    value={name}
                    placeholder="Please enter your name"
                    type="text"
                    size="small"
                    id="bootstrap-input"
                    onChange={handleNameChange}
                  />
                </Stack>
                <Stack>
                  <Typography variant="p" sx={{ fontSize: "12pt" }}>
                    Email
                  </Typography>

                  <OutlinedInput
                    //   defaultValue="react-bootstrap"
                    value={email}
                    placeholder="Please enter phone no"
                    type="email"
                    size="small"
                    id="bootstrap-input"
                    onChange={handleEmailChange}
                  />
                </Stack>

                <Stack>
                  <Typography variant="p" sx={{ fontSize: "12pt" }}>
                    Phone
                  </Typography>

                  <OutlinedInput
                    value={phone}
                    placeholder="Please enter text"
                    type="number"
                    size="small"
                    onChange={handlePhoneChange}
                  />
                </Stack>
                <Stack>
                  <Typography variant="p" sx={{ fontSize: "12pt" }}>
                    User Type
                  </Typography>

                  <Select
                    size="small"
                    value={userType}
                    onChange={handleUserTypeChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">Please Select One</MenuItem>
                    <MenuItem value={"Individual"}>Individual</MenuItem>
                    <MenuItem value={"Company"}>Company</MenuItem>
                  </Select>
                </Stack>
                <Stack>
                  <Typography variant="p" sx={{ fontSize: "12pt" }}>
                    Message
                  </Typography>

                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Minimum 3 rows"
                    style={{ width: "100%" }}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems={"center"}
                ></Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Container>
  );
};

export default Contact;
