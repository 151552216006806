import { Box } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";

import BookSlider from "../components/HomePageComponents/BookSlider/BookSlider";
import BookType from "../components/HomePageComponents/BookType";
import Header from "../components/Header";
import MenuTags from "../components/MenuTags";
import ProductDetails from "./ProductDetails";
import OfferedProducts from "../components/HomePageComponents/OfferedProducts/OfferedProducts";
import Features from "../components/HomePageComponents/Features/Features";
import OverView from "../components/HomePageComponents/OverView/OverView";
import BannerFooter from "../components/HomePageComponents/Banner/BannerFooter";
import OrderProcess from "../components/HomePageComponents/OrderProcess/OrderProcess";
import {
  newBookApi,
  allIslamicBookApi,
  allDressApi,
  allCosmeticsApi,
  ClothTypeApi,
  BookTypeApi,
  ProductTypeGeneralApi,
  allPopularBookApi,
} from "../components/Api/Api";
import { Stack } from "@mui/system";
import ClothSlider from "../components/HomePageComponents/ClothSlider/ClothSlider";
import AddTopBanner from "../components/HomePageComponents/addTopBanner/AddTopBanner";
import AddsTopBanner from "../components/HomePageComponents/addTopBanner/AddsTopBanner";
import DealOfTheDay from "../components/HomePageComponents/DealOfTheDay/DealOfTheDay.js";
import AddsTopBannerPage from "../components/HomePageComponents/addsTopBannerPage/AddsTopBannerPage";
import FeaturedBrands from "../components/HomePageComponents/Featured Brands/FeaturedBrands";
import Footer from "../components/HomePageComponents/Footer/Footer";
import FooterBottom from "../components/HomePageComponents/Footer/FooterBottom";
import BannerSlider from "../components/HomePageComponents/Banner/bannerSlider/BannerSlider";
import PopularCategories from "../components/HomePageComponents/Popular Categories/PopularCategories";
import AppDownload from "../components/HomePageComponents/App Download/AppDownload";
import Statistics from "../components/HomePageComponents/Statistics/Statistics";
import LowerBannerFooter from "../components/HomePageComponents/Banner/bannerSlider/LowerBannerFooter";

const Home = () => {
  return (
    <Box>
      <BannerSlider />
      <Box sx={{ paddingTop: "60px", bgcolor: "#F2F3F5" }}>
        <BookSlider
          apiUrl={newBookApi}
          typeApiUrl={BookTypeApi}
          type="নতুন প্রকাশিত বই"
          route="book"
          all="সকল বই"
        />
      </Box>
      <BookSlider
        apiUrl={allIslamicBookApi}
        typeApiUrl={BookTypeApi}
        type="ইসলামিক বই"
        route="book"
        all="সকল বই"
      />

      <DealOfTheDay />
      <ClothSlider
        apiUrl={allDressApi}
        typeApiUrl={ClothTypeApi}
        type="পোষাক"
        route="clothings"
        all="সকল পোষাক"
      />

      <ClothSlider
        apiUrl={allCosmeticsApi}
        typeApiUrl={ProductTypeGeneralApi}
        type="প্রসাধনী"
        route="cosmetic"
        all="সকল প্রসাধনী"
      />
      <FeaturedBrands />

      <BookSlider
        apiUrl={allPopularBookApi}
        typeApiUrl={BookTypeApi}
        type="জনপ্রিয় বই"
        route="book"
        all="সকল বই"
      />
      <Box sx={{ paddingY: "50px", bgcolor: "#E8EFED" }}>
        <LowerBannerFooter />
      </Box>
      <OrderProcess />
      <Statistics />

      <AppDownload />
      <Footer />
      <FooterBottom />
    </Box>
  );
};

export default Home;
