import { Cached, HighlightOff, ShoppingBag } from "@mui/icons-material";
import {
  Button,
  Container,
  Divider,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { bgcolor, Box, Stack } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import ShoppingCartItem from "../ShoppingCart/ShoppingCartItem";
import CheckOutRightItem from "./CheckOutRightItem";

const CheckoutRight = () => {
  const {
    isEmpty,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();
  const navigate = useNavigate();
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  let total = 0;
  let shipping = 0;
  let quantity = 0;
  for (const product of items) {
    quantity = quantity + product.quantity;
    total = total + product.price * product.quantity;
    // shipping = shipping + product.shipping * product.shipping;
    // shipping = shipping + product.shipping * product.shipping;
  }
  const tax = parseFloat((total * 0.05).toFixed(2)); //5 % tax
  console.log(tax);
  const shippingTotal = parseFloat((items.length * 60).toFixed(2)); //60taka shipping cost
  console.log(shippingTotal);
  const grandTotal = total + tax + shippingTotal; //option 2
  return (
    <Stack
      sx={{
        width: { xs: "100%", sm: "100%", md: "33%" },
        padding: { xs: 0, sm: 0, md: "15px" },
      }}
    >
      <Stack
        spacing={1}
        sx={{
          border: "1px solid #dee2e6",
          padding: "15px",
          marginTop: "-1px",
        }}
      >
        <Typography variant="h6">Order Summary</Typography>
        <Divider />
      </Stack>
      <Stack
        spacing={1}
        sx={{
          border: "1px solid #dee2e6",
          padding: "15px",
          marginTop: "-1px",
        }}
      >
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">Subtotal:</Typography>
            <Typography variant="h6">BDT {cartTotal}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">Shipping:</Typography>
            <Typography variant="h6">BDT 60 </Typography>
          </Stack>
        </Stack>
        <Divider />
        {items.map((element) => (
          <CheckOutRightItem data={element} />
        ))}
      </Stack>
    </Stack>
  );
};

export default CheckoutRight;
