import { FavoriteBorder, HighlightOff } from "@mui/icons-material";
import {
  Button,
  Container,
  Divider,
  FilledInput,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useCart } from "react-use-cart";
import { alltotalContext, totalContext } from "../../App";
import logo from "../../images/logo.png";

const BooknowLeftItem = (props) => {
  const {
    id,
    quantity,
    product_name,
    price,
    count,
    currency,
    pro_pic,
    pro_pic_2,
    category,
  } = props.data;

  let individualAmount = price * quantity;
  return (
    <Paper sx={{ padding: "10px", marginBottom: "10px", bgcolor: "#d0dadb" }}>
      <Stack direction="row" spacing={2}>
        <Box
          component="img"
          sx={{
            height: 60,
            // width: 70,
          }}
          alt="The house from the offer."
          src={pro_pic}
        />
        <Stack justifyContent="center">
          <Typography variant="p">{product_name}</Typography>
          <Typography variant="p">
            BDT {price} X {quantity}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default BooknowLeftItem;
