import * as React from "react";
import {
  Apps,
  FilterList,
  FormatListBulleted,
  HomeOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";

import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Slider,
  Toolbar,
  Typography,
} from "@mui/material";
import { Container, Stack } from "@mui/system";
import { Link } from "react-router-dom";
import CustomBreadcrumb from "../components/CustomBreadcrumb";
import SliderPriceRange from "../components/BookPageComponents/SliderPriceRange";
import CategoryList from "../components/BookPageComponents/CategoryList";
import WriterList from "../components/BookPageComponents/WriterList";
import PublisherList from "../components/BookPageComponents/PublisherList";
import ColorsList from "../components/BookPageComponents/ColorsList";
import BookCard from "../components/HomePageComponents/BookSlider/BookCard";
import { useEffect } from "react";
import { useState } from "react";
import {
  CategoriesApi,
  filterApi,
  generalBookApi,
} from "../components/Api/Api";
import BookCardInside from "../components/HomePageComponents/BookSlider/BookCardInside";
import SubCategoryList from "../components/BookPageComponents/SubCategoryList";
import OfferTypeList from "../components/BookPageComponents/OfferTypeList";
import MinimumOrderList from "../components/BookPageComponents/MinimumOrderList";
import BookTypeList from "../components/BookPageComponents/BookTypeList";
import ResFilterModal from "../components/BookPageComponents/ResBookFilterModal";
import ResBookFilterModal from "../components/BookPageComponents/ResBookFilterModal";

const GeneralBook = () => {
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const [age, setAge] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetch(generalBookApi)
      .then((res) => res.json())
      .then((result) => {
        setData(result);
      });
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleSortChange = (event) => {
    setSort(event.target.value);
  };
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const breadcrumbs = [
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      // onClick={handleClick}
      style={{ textDecoration: "none" }}
    >
      <HomeOutlined
        fontSize="small"
        sx={{ color: "black", marginBottom: "-4px", marginRight: "10px" }}
      />
      <Typography variant="p" color="text.primary">
        Home
      </Typography>
    </Link>,
    <Typography key="3" variant="p" color="text.primary">
      Books
    </Typography>,
  ];

  return (
    <Box>
      <Box sx={{ bgcolor: "#f8f9fa" }}>
        {/* <CustomBreadcrumb breadcrumbs={breadcrumbs} topic={"বই"} /> */}
      </Box>
      <Container sx={{ marginTop: { xs: "58px", md: "5px" } }}>
        <Stack direction="row">
          <Paper
            elevation={3}
            sx={{
              width: "25%",
              boxSizing: "border-box",
              padding: "10px",
              display: { xs: "none", md: "block" },
            }}
          >
            <Stack>
              <SubCategoryList />
              <Divider light sx={{ marginBottom: "10px" }} />
              <BookTypeList />

              <Divider light sx={{ marginBottom: "10px" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 600 }}
              >
                মূল্য
              </Typography>
              <SliderPriceRange />

              <Divider light sx={{ marginBottom: "10px" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 600 }}
              >
                লেখক
              </Typography>
              <WriterList />

              <Divider light sx={{ marginBottom: "10px" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 600 }}
              >
                প্রকাশনী
              </Typography>
              <PublisherList />

              <Divider light sx={{ marginBottom: "10px" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 600 }}
              >
                অফারটাইপ
              </Typography>
              <OfferTypeList />
              <Divider light sx={{ marginBottom: "10px" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 600 }}
              >
                সর্বনিম্ন অর্ডার
              </Typography>
              <MinimumOrderList />
            </Stack>
          </Paper>
          <Stack
            justifyContent="center"
            // alignItems={"center"}
            sx={{
              width: { xs: "100%", md: "75%" },
              padding: { xs: "0%", md: "2%" },
              paddingTop: 0,
            }}
          >
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              justifyContent="space-between"
              sx={{
                bgcolor: "#344052",
                color: "white",
                borderRadius: "8px",
                paddingX: "15px",
                paddingY: "5px",
                marginBottom: "5px",
              }}
            >
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    border: "2px solid white",
                    padding: "3px",
                    borderRadius: "30px",
                    cursor: "pointer",
                    display: { xs: "block", md: "none" },
                    "&:hover": {
                      border: "1px solid black",
                      color: "black",
                      backgroundColor: "white",
                    },
                  }}
                  onClick={handleOpen}
                >
                  <FilterList sx={{ fontSize: "11pt" }} />
                  <Typography variant="p" sx={{ fontSize: "11pt" }}>
                    Filter
                  </Typography>
                </Stack>
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  justifyContent={"right"}
                >
                  <Typography variant="p">Sort by:</Typography>

                  <Select
                    sx={{
                      border: "1px solid white",
                      color: "white",
                    }}
                    size="small"
                    value={sort}
                    onChange={handleSortChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                      <em>Default Sorting</em>
                    </MenuItem>
                    <MenuItem value={30}>newness</MenuItem>
                    <MenuItem value={12}>price: low to high</MenuItem>
                    <MenuItem value={34}>price: high to low</MenuItem>
                  </Select>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems="center"
                sx={{ color: "white" }}
              >
                <Stack direction="row" sx={{ width: "100%" }}>
                  <OutlinedInput
                    onChange={handleSearchChange}
                    size="small"
                    value={search}
                    sx={{
                      color: "white",
                      width: "100%",
                      border: "1px solid white",
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    placeholder="search"
                  />

                  <Button
                    sx={{
                      color: "white",
                      border: "1px solid #dee2e6",
                      width: "10%",
                      // padding: "0.43rem 0",
                      marginLeft: "-1px",
                      borderRadius: 0,
                      borderTopRightRadius: 4,
                      borderBottomRightRadius: 4,
                    }}
                  >
                    <SearchIcon sx={{ color: "white" }} />
                  </Button>
                </Stack>
              </Stack>
            </Stack>

            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                // columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {data.map((data) => (
                  <Grid item xs={12} sm={4} md={3} key={data.pro_pic}>
                    <BookCardInside data={data} />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Divider light sx={{ marginTop: "30px", marginBottom: "20px" }} />

            <Pagination
              count={10}
              variant="outlined"
              color="primary"
              sx={{ margin: "auto" }}
            />
          </Stack>
        </Stack>
      </Container>
      <ResBookFilterModal
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open}
        style={style}
      />
    </Box>
  );
};

export default GeneralBook;

const style = {
  position: "fixed",
  bottom: 0,
  width: "100%",
  height: "0px",
  bgcolor: "background.paper",
  boxShadow: 1,
  overflow: "hidden",
  overflowY: "scroll",
  zIndex: 5,
  transition: "all .5s",
};
