import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, Container, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { KeyboardArrowRight } from "@mui/icons-material";
import Slider from "react-slick";
import { useState } from "react";
import { useEffect } from "react";
import { addBannerTopApi, BookTypeApi } from "../../Api/Api";

function AddsTopBannerPage() {
  //   const [data, setData] = useState([]);
  //   console.log("boorrreee:", data);
  //   useEffect(() => {
  //     fetch(addBannerTopApi)
  //       .then((res) => res.json())
  //       .then((result) => {
  //         console.log(result);
  //         setData(result);
  //       });
  //   }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Box sx={{ background: "red", height: "200px" }}>
      <h3 className="heading">ddfdfdf</h3>
      <Slider {...settings}>
        <div>
          <h3>1</h3>
        </div>
        <div>
          <h3>2</h3>
        </div>
        <div>
          <h3>3</h3>
        </div>
        <div>
          <h3>4</h3>
        </div>
        {/* {data?.map((element) => (
      <Box>
        <Box
          component="img"
          sx={{ width: "100%" }}
          src={element.banner_pic}
        />
      </Box>
    ))} */}
      </Slider>
    </Box>
  );
}

export default AddsTopBannerPage;
