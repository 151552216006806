import { HomeOutlined } from "@mui/icons-material";
import { Container, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import CheckoutRight from "../components/Checkout/CheckoutRight";
import CustomBreadcrumb from "../components/CustomBreadcrumb";
import PaymentCheckoutLeft from "../components/PaymentCheckout/PaymentCheckoutLeft";

const PaymentCheckout = () => {
  const breadcrumbs = [
    <Link 
underline="hover"
key="2"
color="inherit"
href="/material-ui/getting-started/installation/"
// onClick={handleClick}
style={{textDecoration: 'none' }}
>
<HomeOutlined fontSize="small" sx={{ color: 'black', marginBottom: '-4px', marginRight: '10px' }}/>
<Typography variant='p'  color="text.primary">
Home
</Typography>

</Link>,
<Typography key="3"variant='p' color="text.primary">
  Shop
</Typography>,
<Typography key="3"variant='p' color="text.primary">
Shipping
</Typography>,
];
  return (
    <Box>
      <Box sx={{ bgcolor: '#f8f9fa', border: '1px solid #E6E6DF', marginTop: -1 ,  }}><CustomBreadcrumb breadcrumbs={breadcrumbs} topic = {'Shipping'}/></Box>
      <Container>
      <Stack direction="row">
        <PaymentCheckoutLeft />
        <CheckoutRight />
      </Stack>
      </Container>
    </Box>
  );
};

export default PaymentCheckout;
