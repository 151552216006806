import { Language } from "@mui/icons-material";
import { Box, Paper, Stack, Typography } from "@mui/material";
import React from "react";

const StatisticItem = (props) => {
  const { data } = props;
  const { id, icon_pic, icon_set, headline, subhead, type } = data;
  return (
    <Box>
      <Stack
        spacing={2}
        // direction={"row"}
        justifyContent="flex-start"
        alignItems={"flex-start"}
      >
        <Stack
          direction={"row"}
          justifyContent="right"
          alignItems={"center"}
          sx={{
            width: "45px",
            height: "45px",
            backgroundColor: "#e8f0f5de",
            borderRadius: "50%",
          }}
        >
          <Paper
            sx={{ borderRadius: "50%", width: "45px", height: "45px" }}
            elevation={0}
          >
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "#e8f0f5de",
                borderRadius: "50%",
              }}
            >
              {/* <Box
      component="img"
      sx={{
        // padding: "0 12px",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
      }}
      alt="The house from the offer."
      //   src={logo}
    /> */}
              {icon_set === "auto" ? (
                <Language
                  sx={{
                    fontSize: "30pt",
                    color: "#3ecfcb",
                  }}
                />
              ) : (
                <Box
                  component="img"
                  sx={{
                    width: "30px",
                    borderRadius: "50%",
                  }}
                  alt="The house from the offer."
                  src={icon_pic}
                />
              )}
            </Stack>
          </Paper>
        </Stack>
        <Stack sx={{}}>
          <Typography variant="p" sx={{ fontSize: "20pt", fontWeight: 700 }}>
            {headline}
          </Typography>
          <Typography
            variant="p"
            sx={{ fontSize: "10pt", fontWeight: 500, color: "gray" }}
          >
            {subhead}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default StatisticItem;
