import { ShoppingCart, TwentyTwoMp } from "@mui/icons-material";
import { Button, Card, Grid, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import profile from "../../../images/profile.jpg";
import CountDownTimer from "./DateCountdown/CountDownTimer";
import { getRemainingTimeUntillMsTimestamp } from "./DateCountdown/CountdownTimerUtils";

const defaultRemainingTime = {
  seconds: 0,
  minutes: 0,
  hours: 0,
  days: 0,
};
const DayDealCard = (props) => {
  const navigate = useNavigate();
  const { data } = props;
  const {
    ProductTitle,
    product_refer,
    OfferEnd,
    product_info,
    product_name,
    product_type,
    sub_category,
    Newprice,
    ProductType,
    banner_pic,
    code,
    currency,
    Oldprice,
    pr_available,
    pro_pic,
    pro_pic_2,
    refer,
    type,
  } = data;

  let deadline = new Date(OfferEnd).getTime();

  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(deadline);
    }, 1000);
    return () => clearTimeout(intervalId);
  }, []);

  function updateRemainingTime(countdown) {
    setRemainingTime(getRemainingTimeUntillMsTimestamp(countdown));
  }

  const { addItem } = useCart();

  const addToCart = (data) => {
    console.log(data);
    addItem(data);
  };

  console.log("product_refdsfsdfder:", product_refer);
  return (
    <Card sx={{ width: "97%", marginLeft: "10px" }}>
      {/* <CountDownTimer countdownTimestampMs={deadline} /> */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box sx={{ width: "100%" }}>
            <Box
              component="img"
              sx={{ width: "100%", cursor: "pointer" }}
              src={pro_pic}
              onClick={() => {
                navigate("/productdetails-" + product_refer + "-" + type);
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack
            spacing={2}
            justifyContent="left"
            alignItems={"flex-start"}
            sx={{ padding: "20px" }}
          >
            <Stack spacing={1} sx={{ marginTop: { xs: 0, md: "20px" } }}>
              <Typography
                variant="p"
                sx={{ fontSize: "10pt", fontWeight: 500, color: "gray" }}
              >
                {product_info}
              </Typography>
              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600, cursor: "pointer" }}
                onClick={() => {
                  navigate("/productdetails-" + product_refer + "-" + type);
                }}
              >
                {product_name}
              </Typography>
            </Stack>
            <Stack
              spacing={1}
              direction={"row"}
              alignItems="center"
              justifyContent={"left"}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600, color: "darkgreen" }}
              >
                {currency} {pr_available}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: "12pt",
                  fontWeight: 600,
                  color: "darkred",
                  textDecoration: "line-through",
                }}
              >
                {currency} {Oldprice}
              </Typography>
            </Stack>
            {remainingTime.seconds > 0 && remainingTime.minutes > 0 && (
              <Box
                className="btnParentBox"
                sx={{
                  width: "10%",
                  height: "40px",
                  transition: "width 0.5s",
                }}
              >
                <Button
                  variant="contained"
                  className="btnContainer"
                  size="small"
                  sx={{
                    width: "95%",
                    minWidth: "43px",
                    height: "40px",
                    padding: "0px",
                    backgroundColor: "#24AEB1",
                    fontSize: "9pt",
                    "&:hover": {
                      backgroundColor: "#24AEB1",
                    },
                    // paddingY: "2px",
                  }}
                  onClick={() => addToCart(data)}
                >
                  {/* <Stack
          direction={"row"}
          justifyContent={"Left"}
          alignItems="center"
        > */}
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "0%",
                    }}
                  >
                    <ShoppingCart
                      className="btnIcon"
                      sx={{
                        fontSize: "14pt",
                      }}
                    />
                  </span>
                  <span
                    className="btnWritten"
                    style={{
                      padding: 0,
                      // margin: 0,
                      marginLeft: "10px",
                      marginRight: "-10px",
                      width: 0,
                      opacity: 0,
                      fontSize: "8pt",
                      overflow: "hidden",
                    }}
                  >
                    Add to cart
                  </span>
                  {/* </Stack> */}
                </Button>
              </Box>
            )}
            <Stack
              spacing={0.5}
              direction={"row"}
              alignItems="center"
              justifyContent={"left"}
            >
              <Paper>
                <Stack
                  spacing={0}
                  sx={{
                    width: "40px",
                    height: "40px",
                  }}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: "12pt",
                      fontWeight: 600,
                      lineHeight: "18px",
                    }}
                  >
                    {remainingTime.days}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "10pt",
                      fontWeight: 500,
                      color: "gray",
                      lineHeight: "12px",
                    }}
                  >
                    days
                  </Typography>
                </Stack>
              </Paper>
              <Paper>
                <Stack
                  spacing={0}
                  sx={{
                    width: "40px",
                    height: "40px",
                  }}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: "12pt",
                      fontWeight: 600,
                      lineHeight: "18px",
                    }}
                  >
                    {remainingTime.hours}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "10pt",
                      fontWeight: 500,
                      color: "gray",
                      lineHeight: "12px",
                    }}
                  >
                    hours
                  </Typography>
                </Stack>
              </Paper>
              <Paper>
                <Stack
                  spacing={0}
                  sx={{
                    width: "40px",
                    height: "40px",
                  }}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: "12pt",
                      fontWeight: 600,
                      lineHeight: "18px",
                    }}
                  >
                    {remainingTime.minutes}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "10pt",
                      fontWeight: 500,
                      color: "gray",
                      lineHeight: "12px",
                    }}
                  >
                    min
                  </Typography>
                </Stack>
              </Paper>
              <Paper>
                <Stack
                  spacing={0}
                  sx={{
                    width: "40px",
                    height: "40px",
                  }}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: "12pt",
                      fontWeight: 600,
                      lineHeight: "18px",
                    }}
                  >
                    {remainingTime.seconds}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "10pt",
                      fontWeight: 500,
                      color: "gray",
                      lineHeight: "12px",
                    }}
                  >
                    sec
                  </Typography>
                </Stack>
              </Paper>
            </Stack>
            {remainingTime.seconds <= 0 &&
              remainingTime.minutes <= 0 &&
              remainingTime.hours <= 0 &&
              remainingTime.days <= 0 && (
                <Button
                  variant="contained"
                  size="small"
                  sx={{ bgcolor: "darkred", color: "white", fontSize: "9pt" }}
                >
                  Offer Closed
                </Button>
              )}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default DayDealCard;
