import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import BookCard from "./BookCard";
import { Button, Container, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { KeyboardArrowRight } from "@mui/icons-material";
import Slider from "react-slick";
import { useState } from "react";
import { useEffect } from "react";
import { BookTypeApi } from "../../Api/Api";
import "./BookSlider.css";
import { useNavigate } from "react-router-dom";

const BookSlider = (props) => {
  const { apiUrl, typeApiUrl, route } = props;
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [bookType, setBookType] = useState([]);
  useEffect(() => {
    fetch(apiUrl)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setData(result);
      });

    fetch(typeApiUrl)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setBookType(result.slice(20, 28));
      });
  }, []);
  const settings = {
    infinite: true,
    arrow: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    draggable: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings2 = {
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    draggable: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ bgcolor: "#F2F3F5", paddingY: "20px" }}>
      <Container>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "0% 0" }}
        >
          <Typography sx={{ fontWeight: 700, fontSize: "13pt" }}>
            {props.type}
          </Typography>
          <Button
            variant="contained"
            endIcon={<KeyboardArrowRight />}
            sx={{ bgcolor: "black", fontSize: "9pt" }}
            onClick={() => {
              navigate("/" + route);
            }}
          >
            {props.all}
          </Button>
        </Stack>
        <Divider sx={{ marginY: "20px" }} />

        <Slider {...settings}>
          {data?.map((element) => (
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              key={element.pro_pic}
            >
              <BookCard data={element} />
            </Stack>
          ))}
        </Slider>
        <Box sx={{ paddingTop: "40px" }}>
          <Slider {...settings2}>
            {bookType?.map((element) => (
              <Box key={element.type_name_bn}>
                <Button
                  variant="outlined"
                  sx={{ width: { xs: "100%", md: "90%" } }}
                >
                  {element.type_name_bn}
                </Button>
              </Box>
            ))}
          </Slider>
        </Box>
      </Container>
    </Box>
  );
};

export default BookSlider;
