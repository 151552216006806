import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";

const Package = () => {
  return (
    <Container>
      <Typography variant="h6">আপকামিং ফিচার</Typography>
    </Container>
  );
};

export default Package;
