// import "./product-image-slider.css";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Autoplay } from "swiper";
// import { Autoplay, Pagination, Navigation } from "swiper";
import { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import "./BannerSlider.css";
import { bannerApi } from "../../../Api/Api";
import BannerFooter from "../BannerFooter";
// import img1 from "../../../../../../../assets/sopnobari/home/Experts/img1.jpg";
// import img1 from "../../../../../../../assets/reviews/img1.jpg";
// const sectionStyleImg = {
//   height: "auto",
//   width: "100%",
//   padding: { lg: "80px 20px 80px 60px", md: "20px", sm: "0px", xs: "0px" },
//   //   boxShadow: "0px 11px 59px 0px rgb(0 0 0 / 15%)",
//   transition: "all 0.3s ease-in-out",
//   //   position: "relative",
//   //   zIndex: "1",
// };
const headerTitle = {
  color: "#00C194",
  fontWeight: "500",
  fontSize: "24px",
  lineHeight: "30px",
  textAlign: "right",
  textTransform: "capitalize",
  marginBottom: "10px",
};
const headerTitle1 = {
  color: "#000",
  fontWeight: "700",
  fontSize: "1rem",
  lineHeight: "30px",
  textAlign: "right",
  textTransform: "capitalize",
};
const BannerSlider = (props) => {
  const [activeThumb, setActiveThumb] = useState();
  const [bannerData, setBannerData] = useState([]);
  useEffect(() => {
    fetch(bannerApi)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setBannerData(data);
      });
  }, []);
  return (
    <Box
      className="prdImgSliderArea"
      sx={{ position: "relative", marginTop: "60px" }}
    >
      <Swiper
        loop={true}
        spaceBetween={23.5}
        navigation={true}
        modules={[Autoplay, Navigation, Thumbs]}
        grabCursor={true}
        thumbs={{ swiper: activeThumb }}
        className="product-images-slider"
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {/* {
                props.images.map((item, index) => (
                    <SwiperSlide key={index}>
                        <img src={item} alt="product images" />
                    </SwiperSlide>
                ))
            } */}

        {bannerData.map((obj, idx) => (
          <SwiperSlide>
            {" "}
            <Box
              style={
                {
                  // paddingBottom: "100px",
                  // paddingX: { lg: "100px", md: "50px", sm: "30px", xs: "20px" },
                  // paddingY:{lg: "50px", md:"50px" ,sm:"30px"},
                  // overflow: "hidden",
                  // background: "#fff",
                  // height:"500px"
                }
              }
            >
              {" "}
              <Grid
                sx={{
                  paddingX: {
                    lg: "50px",
                    md: "30px",
                    sm: "10px",
                    xs: "30px",
                  },
                  paddingY: { lg: "50px", md: "50px", sm: "30px", xs: "20px" },
                }}
                className="parentDivSection"
                style={{
                  height: "100%",
                  backgroundImage: `url('${obj.banner_pic}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                container
                spacing={3}
              >
                <Grid
                  order={{ lg: 2, md: 3, sm: 3, xs: 3 }}
                  item
                  xs={12}
                  md={6}
                >
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={
                        {
                          // paddingLeft: "120px",
                          // paddingRight: "120px",
                        }
                      }
                    >
                      <Typography
                        variant="subtitle2"
                        component="p"
                        sx={headerTitle}
                      >
                        {obj.headline}
                      </Typography>
                      <Typography
                        variant="h3"
                        component="h2"
                        sx={{
                          //   display: { xs: "none" },
                          color: "#fff",
                          //   width: "250px",
                          fontWeight: "700",
                          fontSize: "36px",
                          lineHeight: "1.2",
                          textAlign: "right",
                          marginTop: "5px",
                          marginBottom: "15px",
                          letterSpacing: "0.5px",
                          textTransform: "initialize",
                          // cursor: "pointer",
                        }}
                      >
                        {obj.title}
                      </Typography>

                      <Typography
                        variant="subtitle2"
                        component="p"
                        sx={headerTitle1}
                      >
                        {obj.subtitle}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "20px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          //   color="success"
                          // width="15%"

                          sx={{
                            px: 1,
                            py: 1,
                            fontSize: "15px",
                            color: "#fff",
                            backgroundColor: "#00a376",
                            transition: "all 0.3s ease-in-out",

                            "&:hover": {
                              color: "#fff",
                              backgroundColor: "rgb(12, 54, 39)",
                              // transform: "rotate(45deg)",
                            },
                            cursor: "pointer",
                          }}
                          type="submit"
                          size="small"
                          // onClick={handleOpen}
                        >
                          বিস্তারিত
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  order={{ lg: 3, md: 2, sm: 2, xs: 2 }}
                  item
                  xs={12}
                  md={6}
                >
                  <Box
                    className="sliderImgArea"
                    sx={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      order: "1",
                    }}
                  >
                    <Box
                      className="sliderImg"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{ height: "400px", width: "auto" }}
                        src={obj.product_pic}
                        alt="banner_pic"
                      />
                      <span className="positionedPrice">
                        মূল্য <br />
                        {obj.price}
                      </span>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          bottom: { xs: "-70px", md: "-42px" },
          zIndex: 6,
        }}
      >
        <BannerFooter />
      </Box>
    </Box>
  );
};

BannerSlider.propTypes = {
  images: PropTypes.array.isRequired,
};

export default BannerSlider;
