import { Facebook, MoreVert, Twitter, YouTube } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import profile from "../../images/profile.jpg";
import "./profile.css";

const Profile = () => {
  return (
    <Card sx={{ minWidth: 275, bgcolor: "azure" }}>
      <CardContent>
        <Stack direction="row" justifyContent="right" alignItems="center">
          <MoreVert sx={{ color: "gray" }} />
        </Stack>
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <Avatar
            alt="Remy Sharp"
            src={profile}
            sx={{ width: 70, height: 70 }}
          />
          <Stack justifyContent="center" alignItems="center">
            <Typography variant="p">Tamim Ikbal</Typography>
            <Typography variant="p">Member Since - 1997</Typography>
          </Stack>
          <Stack justifyContent="center" alignItems="center">
            <Typography variant="caption" sx={{ textAlign: "center" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam,
              vero! Rem quam cumque sit aut
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              size="small"
              sx={{
                bgcolor: "#00acee",
                color: "white",
                width: "20px",
                height: "20px",
              }}
            >
              <Twitter
                sx={{
                  fontSize: "12pt",
                }}
              />
            </IconButton>
            <IconButton
              size="small"
              sx={{
                bgcolor: "#3b5998",
                color: "white",
                width: "20px",
                height: "20px",
              }}
            >
              <Facebook
                sx={{
                  fontSize: "10pt",
                }}
              />
            </IconButton>
            <IconButton
              size="small"
              sx={{
                bgcolor: "#c4302b",
                color: "white",
                width: "20px",
                height: "20px",
              }}
            >
              <YouTube
                sx={{
                  fontSize: "12pt",
                }}
              />
            </IconButton>
            <IconButton
              size="small"
              sx={{
                bgcolor: "#00acee",
                color: "white",
                width: "20px",
                height: "20px",
              }}
            >
              <Twitter
                sx={{
                  fontSize: "12pt",
                }}
              />
            </IconButton>
          </Stack>
        </Stack>
      </CardContent>

      <Stack justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          sx={{ width: "50%", marginBottom: "52px" }}
          size="small"
        >
          <Typography variant="caption">View Profile</Typography>
        </Button>
      </Stack>
    </Card>
    // <Stack justifyContent="start" alignItems="center">
    //   <Avatar
    //     alt="Remy Sharp"
    //     src="/static/images/avatar/1.jpg"
    //     sx={{ width: 70, height: 70 }}
    //   />
    //   <Typography variant="p">Writer Name</Typography>
    //   <Typography variant="caption">Member since - 2022</Typography>
    //   <Typography variant="caption">some info</Typography>
    // </Stack>
  );
};

export default Profile;
