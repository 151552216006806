import { HomeOutlined } from "@mui/icons-material";
import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import CheckoutRight from "../components/Checkout/CheckoutRight";
import CustomBreadcrumb from "../components/CustomBreadcrumb";
import PaymentMethodLeft from "../components/paymentMethod/PaymentMethodLeft";
import PaymentMethodRight from "../components/paymentMethod/PaymentMethodRight";


const PaymentMethod = () => {
    const breadcrumbs = [
        <Link 
    underline="hover"
    key="2"
    color="inherit"
    href="/material-ui/getting-started/installation/"
    // onClick={handleClick}
    style={{textDecoration: 'none' }}
    >
    <HomeOutlined fontSize="small" sx={{ color: 'black', marginBottom: '-4px', marginRight: '10px' }}/>
    <Typography variant='p'  color="text.primary">
    Home
    </Typography>
    
    </Link>,
    <Typography key="3"variant='p' color="text.primary">
      Shop
    </Typography>,
    <Typography key="3"variant='p' color="text.primary">
    Payment Method
    </Typography>,
    ];
    return (
        <Box>
      <Box sx={{ bgcolor: '#f8f9fa', border: '1px solid #E6E6DF', marginTop: -1 ,  }}><CustomBreadcrumb breadcrumbs={breadcrumbs} topic = {'Payment Method'}/></Box>
      <Container>
      <Stack spacing={{ xs:2, sm:0 }} direction={{xs:'column', md: "row"}}>
        <PaymentMethodLeft />
        <PaymentMethodRight />
      </Stack>
      </Container>
    </Box>
    );
};

export default PaymentMethod;