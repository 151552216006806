import { Cached, HighlightOff, HomeOutlined, ShoppingBag } from "@mui/icons-material";
import {
  Button,
  Container,
  Divider,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { bgcolor, Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomBreadcrumb from "../components/CustomBreadcrumb";
import CartLeft from "../components/ShoppingCart/CartLeft";
import CartRight from "../components/ShoppingCart/CartRight";

const ShoppingCart = () => {
  const [age, setAge] = React.useState("");
  const [updateCart, setUpdateCart] = useState([]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

        const breadcrumbs = [
          <Link 
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      // onClick={handleClick}
      style={{textDecoration: 'none' }}
      >
      <HomeOutlined fontSize="small" sx={{ color: 'black', marginBottom: '-4px', marginRight: '10px' }}/>
      <Typography variant='p'  color="text.primary">
      Home
      </Typography>

      </Link>,
      <Typography key="3"variant='p' color="text.primary">
        Shop
      </Typography>,
      <Typography key="3"variant='p' color="text.primary">
        Shop Cart
      </Typography>,
      ];
      
       
  useEffect(()=>{
    setUpdateCart(JSON.parse(localStorage.getItem('cart')));
  },[updateCart])
      
      const handleRemoveFromCartBtn = (product) => {
        console.log(product.product_refer);
        console.log("clicked",product.product_refer)
        let newCart = updateCart.filter(element => 
          element.product_refer !== product.product_refer
        );
        console.log('lastcard',newCart)
    
        setUpdateCart(newCart)
        localStorage.setItem('cart', JSON.stringify(newCart))
      };
    

  return (
    <Box>
      <Box sx={{ bgcolor: '#f8f9fa', border: '1px solid #E6E6DF', marginTop: -1 ,  }}><CustomBreadcrumb breadcrumbs={breadcrumbs} topic = {'Shop Cart'}/></Box>
      <Container>
      <Stack spacing={2} direction={{xs:'column', md: "row"}}>
        <CartLeft handleRemoveFromCartBtn={handleRemoveFromCartBtn} />
        <CartRight />
      </Stack>
      </Container>
    </Box>
  );
};

export default ShoppingCart;
