import { HomeOutlined } from "@mui/icons-material";
import {
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import CustomBreadcrumb from "../components/CustomBreadcrumb";
import EachCategory from "../components/ShopCategories/EachCategory";

const ShopCategories = () => {
  const [fakeData, setFakeData] = React.useState([]);
  React.useEffect(() => {
    // fetch(props.url)
    fetch("https://horekrokombd.com//api/web_api/product_all.php")
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setFakeData(result);
      });
  }, []);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      // onClick={handleClick}
      style={{ textDecoration: "none" }}
    >
      <HomeOutlined
        fontSize="small"
        sx={{ color: "black", marginBottom: "-4px", marginRight: "10px" }}
      />
      <Typography variant="p" color="text.primary">
        Home
      </Typography>
    </Link>,
    <Typography key="3" variant="p" color="text.primary">
      Shop
    </Typography>,
    <Typography key="3" variant="p" color="text.primary">
      Shop Categories
    </Typography>,
  ];
  return (
    <Box>
      <Box
        sx={{ bgcolor: "#f8f9fa", border: "1px solid #E6E6DF", marginTop: -1 }}
      >
        <CustomBreadcrumb breadcrumbs={breadcrumbs} topic={"Shop Categories"} />
      </Box>
      <Container sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          columns={{ xs: 4, sm: 8, md: 12, lg: 16 }}
        >
          {fakeData.map((data) => (
            <Grid item xs={2} sm={4} md={4} key={data.pro_pic}>
              <EachCategory data={data} />
            </Grid>
          ))}
        </Grid>
        <Typography
          variant="h4"
          sx={{ textAlign: "center", marginTop: "60px" }}
        >
          Popular Brands
        </Typography>
        <Divider sx={{ marginTop: "16px" }} />
        <Box sx={{ marginTop: "15px" }}>
          <Grid
            container
            spacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
            columns={{ xs: 4, sm: 12, md: 12, lg: 20 }}
          >
            {fakeData.map((data) => (
              <Grid item xs={2} sm={4} md={3} lg={4} key={data.pro_pic}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{ border: "1px solid lightgray", borderRadius: "5px" }}
                >
                  <Box
                    component="img"
                    sx={{
                      padding: "12px",
                      width: "90%",
                      margin: "auto",
                    }}
                    alt="The house from the offer."
                    src={data.pro_pic}
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ShopCategories;
