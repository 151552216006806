import { Box, Container } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Pages from "./Pages/Pages";
import Header from "./components/Header";
import MenuTags from "./components/MenuTags";
import Footer from "./components/Footer";
import { createContext, useState } from "react";
import Login from "./Pages/Login";

export const CartContext = createContext();
export const TotalContext = createContext();
export const alltotalContext = createContext();
export const PDstoragedContext = createContext();
export const UserContext = createContext();
function App() {
  const [cart, setCart] = useState([]);

  const [allTotal, setAllTotal] = useState(0);
  const [loggedInUser, setLoggedInUser] = useState({});

  return (
    <UserContext.Provider value={[loggedInUser, setLoggedInUser]}>
      <alltotalContext.Provider value={[allTotal, setAllTotal]}>
        <CartContext.Provider value={[cart, setCart]}>
          <BrowserRouter>
            <Header />
            <MenuTags />
            <Pages />
            <Routes>
              <Route path="/login" element={<Login />} />
            </Routes>
          </BrowserRouter>
        </CartContext.Provider>
      </alltotalContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
