import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, Container, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { KeyboardArrowRight } from "@mui/icons-material";
import Slider from "react-slick";
import { useState } from "react";
import { useEffect } from "react";
import ClothCard from "./ClothCard";
import { ClothTypeApi } from "../../Api/Api";
import { useNavigate } from "react-router-dom";
const ClothSlider = (props) => {
  const { apiUrl, typeApiUrl, route } = props;
  const navigate = useNavigate();
  console.log("apiUrl:", apiUrl);
  const [data, setData] = useState([]);
  const [clothType, setClothType] = useState([]);
  useEffect(() => {
    fetch(apiUrl)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setData(result);
      });
    fetch(typeApiUrl)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setClothType(result.slice(37, 44));
      });
  }, []);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    draggable: true,
    arrow: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    draggable: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box sx={{ bgcolor: "", paddingY: "20px" }}>
      <Container sx={{ flexGrow: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "0% 0" }}
        >
          <Typography sx={{ fontWeight: 700, fontSize: "13pt" }}>
            {props.type}
          </Typography>
          <Button
            variant="contained"
            endIcon={<KeyboardArrowRight />}
            sx={{ bgcolor: "black", fontSize: "9pt" }}
            onClick={() => {
              navigate("/" + route);
            }}
          >
            {props.all}
          </Button>
        </Stack>
        <Divider sx={{ marginY: "20px" }} />

        <Slider {...settings}>
          {data?.map((element) => (
            <Box key={element.pro_pic}>{<ClothCard data={element} />}</Box>
          ))}
        </Slider>
        <Box sx={{ paddingTop: "40px" }}>
          <Slider {...settings2}>
            {clothType?.map((element) => (
              <Box key={element.type_name_bn}>
                <Button
                  variant="outlined"
                  sx={{ width: { xs: "100%", md: "90%" } }}
                >
                  {element.type_name_bn}
                </Button>
              </Box>
            ))}
          </Slider>
        </Box>
      </Container>
    </Box>
  );
};

export default ClothSlider;
