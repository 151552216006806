import * as React from "react";
import {
  Apps,
  FormatListBulleted,
  HomeOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";

import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Slider,
  Toolbar,
  Typography,
} from "@mui/material";
import { Container, Stack } from "@mui/system";
import { Link } from "react-router-dom";
import CustomBreadcrumb from "../components/CustomBreadcrumb";
import SliderPriceRange from "../components/BookPageComponents/SliderPriceRange";
import CategoryList from "../components/BookPageComponents/CategoryList";
import WriterList from "../components/BookPageComponents/WriterList";
import PublisherList from "../components/BookPageComponents/PublisherList";
import ColorsList from "../components/BookPageComponents/ColorsList";
import BookCard from "../components/HomePageComponents/BookSlider/BookCard";
import { useEffect } from "react";
import { useState } from "react";
import {
  allGeneralApi,
  CategoriesApi,
  ClothTypeApi,
  CosmeticsApi,
  dressesApi,
  generalAllApi,
  preOrderApi,
} from "../components/Api/Api";
import ClothCard from "../components/HomePageComponents/ClothSlider/ClothCard";
import BookCardInside from "../components/HomePageComponents/BookSlider/BookCardInside";
import ClothCardInside from "../components/HomePageComponents/ClothSlider/ClothCardInside";
import SubCategoryList from "../components/ClothPage Components/SubCategoryList";
import CosmeticTypeList from "../components/cosmeticsPage Components/CosmeticTypeList";
import CosmeticsBrandList from "../components/cosmeticsPage Components/CosmeticsBrandList";
import OriginList from "../components/ClothPage Components/OriginList";
import MinimumOrderList from "../components/BookPageComponents/MinimumOrderList";
import GeneralBrandList from "../components/GeneralPage Components/GeneralBrandList";
import GeneralSubCategoryList from "../components/GeneralPage Components/GeneralSubCategoryList";
import GeneralTypeList from "../components/GeneralPage Components/GeneralTypeList";

const GeneralPage = () => {
  const [age, setAge] = useState("");
  const [data, setData] = useState([]);
  console.log("fdfdfeerf:", data);

  useEffect(() => {
    fetch(generalAllApi)
      .then((res) => res.json())
      .then((result) => {
        setData(result);
      });
  }, []);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const breadcrumbs = [
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      // onClick={handleClick}
      style={{ textDecoration: "none" }}
    >
      <HomeOutlined
        fontSize="small"
        sx={{ color: "black", marginBottom: "-4px", marginRight: "10px" }}
      />
      <Typography variant="p" color="text.primary">
        Home
      </Typography>
    </Link>,
    <Typography key="3" variant="p" color="text.primary">
      Books
    </Typography>,
  ];

  return (
    <Box>
      <Box sx={{ bgcolor: "#f8f9fa" }}>
        {/* <CustomBreadcrumb breadcrumbs={breadcrumbs} topic={"বই"} /> */}
      </Box>
      <Container sx={{ marginTop: "2%" }}>
        <Stack direction="row">
          <Paper
            elevation={3}
            sx={{ width: "25%", boxSizing: "border-box", padding: "10px" }}
          >
            <Stack>
              <GeneralSubCategoryList />
              <Divider light sx={{ marginBottom: "10px" }} />
              <GeneralTypeList />

              <Divider light sx={{ marginBottom: "10px" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 600 }}
              >
                মূল্য
              </Typography>
              <SliderPriceRange />

              <Divider light sx={{ marginBottom: "10px" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 600 }}
              >
                ব্র্যান্ড
              </Typography>
              <GeneralBrandList />

              <Divider light sx={{ marginBottom: "10px" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 600 }}
              >
                অফারটাইপ
              </Typography>
              <PublisherList />
              <Divider light sx={{ marginBottom: "10px" }} />

              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 600 }}
              >
                সর্বনিম্ন অর্ডার
              </Typography>
              <MinimumOrderList />
            </Stack>
          </Paper>
          <Stack
            justifyContent="flex-start"
            sx={{
              width: "75%",
              padding: "2%",
              paddingTop: 0,
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                bgcolor: "#344052",
                color: "white",
                borderRadius: "8px",
                paddingX: "15px",
                paddingY: "5px",
                marginBottom: "5px",
              }}
            >
              <Stack direction="row" alignItems="center">
                <Typography variant="p">Sort by:</Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <Select
                    sx={{
                      border: "1px solid white",
                      color: "white",
                    }}
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                      <em>Default Sorting</em>
                    </MenuItem>
                    <MenuItem value={30}>Sort by newness</MenuItem>
                    <MenuItem value={30}>Sort by price: low to high</MenuItem>
                    <MenuItem value={30}>Sort by price: high to low</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ color: "white" }}
              >
                <Typography variant="p">Show:</Typography>
                <FormControl
                  sx={{ m: 1, minWidth: 60, color: "white" }}
                  size="small"
                >
                  <Select
                    sx={{ color: "white", border: "1px solid white" }}
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                      <em>5</em>
                    </MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                    <MenuItem value={16}>16</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
                <Stack direction="row">
                  <OutlinedInput
                    onChange={handleChange}
                    size="small"
                    sx={{
                      color: "white",
                      width: "70%",
                      border: "1px solid white",
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    placeholder="খোঁজ দ্য সার্চ"
                  />

                  <Button
                    sx={{
                      color: "white",
                      border: "1px solid #dee2e6",
                      width: "8%",
                      padding: "0.43rem 0",
                      marginLeft: "-1px",
                      borderRadius: 0,
                      borderTopRightRadius: 4,
                      borderBottomRightRadius: 4,
                    }}
                  >
                    <SearchIcon sx={{ color: "white" }} />
                  </Button>
                </Stack>
              </Stack>
            </Stack>

            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {data.map((data) => (
                  <Grid item xs={12} sm={4} md={3} key={data.pro_pic}>
                    <ClothCardInside data={data} />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Divider light sx={{ marginTop: "30px", marginBottom: "20px" }} />

            <Pagination
              count={10}
              variant="outlined"
              color="primary"
              sx={{ margin: "auto" }}
            />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default GeneralPage;
