import { Email, Lock, SettingsRounded } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { Fragment } from 'react';
import loginLogo from "../images/loginLogo.png";
import loginBG from "../images/loginBG.jpg";

const Login = () => {
    return (
        <Box sx={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${loginBG})`, objectFit: 'cover' }} >
        <Box sx={{ height: '75%', width: '45%', bgcolor: 'white', padding: '2% 0', borderRadius: '12px' }}>
            <Stack spacing={1} justifyContent='center' alignItems='center'>
            <Box
                component="img"
                sx={{
                    padding: "0 12px",
                    width: 110,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                }}
                alt="The house from the offer."
                src={loginLogo}
                />
                <Stack justifyContent='center' alignItems='center'>
                    <Typography variant='h4' fontWeight={600}>Welcome Back</Typography>
                    <Typography variant='p' fontWeight={700} color='gray'>Sign To Continue</Typography>
                </Stack>
                
                <TextField id="outlined-basic"  
                      label={
                            <Stack direction='row' alignItems="center">
                            <Email className="myIcon" fontSize="small" />
                            Number/Email
                            
                            </Stack>
                        } variant="outlined" sx={{ width: '90%' }} />
                <TextField id="outlined-basic"  
                      label={
                            <Stack direction='row' alignItems="center">
                            <Lock className="myIcon" fontSize="small" />
                            Password
                            
                            </Stack>
                        } variant="outlined" sx={{ width: '90%' }} />
                <Stack direction='row' justifyContent='space-between' alignItems= 'center' sx={{ width: '90%' }}>
                    <FormControlLabel control={<Checkbox defaultChecked />} label="Remember me" />
                    <Button>FORGOT PASSWORD?</Button>
                </Stack>
                <Button variant='contained' sx={{ width: '90%' }} size='large'>LOGIN</Button>
                <Stack direction='row' justifyContent='center' alignItems= 'center' sx={{ width: '90%', height: '40px' }}>
                    <Typography variant='caption' fontWeight={600}>Do you have any account?</Typography>
                    <Button size='small'><Typography variant='caption' fontWeight={600}>Do you have any account?</Typography></Button>
                </Stack>

            </Stack>
        </Box>
    </Box>
    );
};

export default Login;