import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { countryListApi, fetchUserApi } from "../components/Api/Api";
import { useEffect } from "react";
import "./css/BookNow.css";
import { useParams } from "react-router-dom";
import { Add, Edit, Remove } from "@mui/icons-material";
import PaymentMethod from "../components/Booknow Component/PaymentMethod";
import Bkash from "../images/bkash.png";
import Nagad from "../images/nagad.png";
import BooknowTopSection from "../components/Booknow Component/BooknowTopSection";
import { useCart } from "react-use-cart";
import BooknowLeftItem from "../components/Booknow Component/BooknowLeftItem";

const Booknow = () => {
  // const { refer } = useParams();
  const {
    isEmpty,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();
  const [toggle, setToggle] = useState(true);
  console.log("toggle:", toggle);
  const [grandTotal, setGrandTotal] = useState(0);
  const [service, setService] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [gateway, setGeteway] = useState("bkash");
  const [gatewayCharge, setGatewayCharge] = useState(0);

  const [fetchedPhone, setFetchedPhone] = useState("");
  const [fetchedEmail, setFetchedEmail] = useState("");
  const [fetchedName, setFetchedName] = useState("");
  const [fetchedCity, setFetchedCity] = useState("");
  const [fetchedAddress, setFetchedAddress] = useState("");
  const [serviceStartDate, setServiceStartDate] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [message, setMessage] = useState("");

  const [fetchedCountry, setFetchedCountry] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [fetchUser, setFetchUser] = useState([]);
  const [requestData, setRequestData] = useState("");

  let total = 0;
  let howMany = 0;
  for (const product of items) {
    howMany = howMany + product.quantity;
    total = total + product.price * product.quantity;
    // shipping = shipping + product.shipping * product.shipping;
    // shipping = shipping + product.shipping * product.shipping;
  }

  const gatewayChargeList = [
    {
      type: "bkash",
      charge: 1.5,
      pic: Bkash,
    },
    {
      type: "nagad",
      charge: 1.2,
      pic: Nagad,
      api: "http://yeapbe.com/ghpay/nagad/index.js",
    },
  ];

  useEffect(() => {
    setGrandTotal((gatewayCharge / 100) * total + total);
  }, [quantity, gatewayCharge]);

  // useEffect(() => {
  //   fetch(serviceApi)
  //     .then((res) => res.json())
  //     .then((result) => {
  //       let found = result.find((element) => element.service_refer === refer);
  //       setService(found);
  //     })
  //     .catch((error) => console.log("ServiceApiError=> ", error));
  // }, []);

  useEffect(() => {
    fetch(countryListApi)
      .then((res) => res.json())
      .then((result) => {
        setCountryList(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, []);

  useEffect(() => {
    const formData = new FormData();
    formData.append("query", requestData);

    fetch(fetchUserApi, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setFetchUser(result);
        setFetchedPhone(result.p_phone);
        setFetchedEmail(result.p_email);
        setFetchedName(result.p_nameS);
        setFetchedCity(result.p_city);
        setFetchedCountry(result.posterCountry);
        setFetchedAddress(result.p_address);
        console.log("resultc:", result);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, [requestData]);

  const handleGatewayCharge = (value) => {
    setGatewayCharge(value);
  };
  const handlePhoneChange = (event) => {
    setFetchedPhone(event.target.value);
    setRequestData(event.target.value);
  };
  const handleEmailChange = (event) => {
    setFetchedEmail(event.target.value);
    setRequestData(event.target.value);
  };
  const handleNameChange = (event) => {
    setFetchedName(event.target.value);
  };
  const handleCountryChange = (event) => {
    setFetchedCountry(event.target.value);
  };
  const handleCityChange = (event) => {
    setFetchedCity(event.target.value);
  };
  const handleAddressChange = (event) => {
    setFetchedAddress(event.target.value);
  };
  const handleDateChange = (event) => {
    setDeliveryDate(event.target.value);
  };
  const handleServiceStartDate = (event) => {
    setServiceStartDate(event.target.value);
  };

  const handleMessage = (event) => {
    setMessage(event.target.value);
  };

  const addQuantity = () => {
    let q = quantity;
    setQuantity(q + 1);
  };
  const reduceQuantity = () => {
    let q = quantity;
    if (q <= 1) {
      setQuantity(1);
    } else {
      setQuantity(q - 1);
    }
  };
  console.log("final vlaue ", fetchedPhone);
  return (
    <Container>
      <BooknowTopSection />
      <Grid container spacing={2} marginTop={3}>
        <Grid item xs={3}>
          <Paper
            sx={{
              padding: "15px",
              bgcolor: "#14506A",
              color: "white",
              height: "100%",
            }}
          >
            <Box sx={{ paddingBottom: "30px" }}>
              <Typography
                variant="p"
                sx={{ fontSize: "14pt", fontWeight: 700 }}
              >
                অর্ডারকৃত পণ্যের তালিকা
              </Typography>
            </Box>
            {items.map((element) => (
              <BooknowLeftItem data={element} />
            ))}
          </Paper>
        </Grid>
        <Grid item xs={6} sx={{ marginTop: "15px" }}>
          <Paper padding={3}>
            <Box className={toggle ? "" : "hideForm"}>
              <Grid container spacing={2} paddingLeft={5}>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="p" sx={{ fontSize: "12pt" }}>
                      Phone
                    </Typography>

                    <BootstrapInput
                      //   defaultValue="react-bootstrap"
                      value={fetchedPhone}
                      placeholder="Please enter text"
                      type="number"
                      size="small"
                      id="bootstrap-input"
                      onChange={handlePhoneChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="p" sx={{ fontSize: "12pt" }}>
                      Email
                    </Typography>
                    <BootstrapInput
                      value={fetchedEmail}
                      //   defaultValue="react-bootstrap"
                      placeholder="Please enter text"
                      type="email"
                      size="small"
                      id="bootstrap-input"
                      onChange={handleEmailChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="p" sx={{ fontSize: "12pt" }}>
                      Name
                    </Typography>
                    <BootstrapInput
                      value={fetchedName}
                      //   defaultValue="react-bootstrap"
                      placeholder="Please enter text"
                      type="text"
                      size="small"
                      id="bootstrap-input"
                      onChange={handleNameChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="p" sx={{ fontSize: "12pt" }}>
                      Country
                    </Typography>
                    <Select
                      size="small"
                      value={fetchedCountry}
                      onChange={handleCountryChange}
                      displayEmpty
                      sx={{ width: "82%" }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {countryList?.map((element) => (
                        <MenuItem value={element.country_name}>
                          {element.country_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>

                  {/* <BootstrapInput
                  //   defaultValue="react-bootstrap"
                  placeholder="Please enter text"
                  type="email"
                  size="small"
                  id="bootstrap-input"
                /> */}
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="p" sx={{ fontSize: "12pt" }}>
                      City
                    </Typography>
                    <BootstrapInput
                      //   defaultValue="react-bootstrap"
                      value={fetchedCity}
                      placeholder="Please enter text"
                      type="text"
                      size="small"
                      id="bootstrap-input"
                      onChange={handleCityChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="p" sx={{ fontSize: "12pt" }}>
                      Address
                    </Typography>
                    <BootstrapInput
                      //   defaultValue="react-bootstrap"
                      value={fetchedAddress}
                      placeholder="Please enter text"
                      type="text"
                      size="small"
                      id="bootstrap-input"
                      onChange={handleAddressChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack sx={{ width: "100%" }}>
                    <Typography variant="p" sx={{ fontSize: "12pt" }}>
                      Service Start Date
                    </Typography>
                    <BootstrapInput
                      value={serviceStartDate}
                      sx={{ width: "100%" }}
                      //   defaultValue="react-bootstrap"
                      placeholder="Please enter text"
                      type="date"
                      size="small"
                      id="bootstrap-input"
                      onChange={handleServiceStartDate}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack sx={{ width: "100%" }}>
                    <Typography variant="p" sx={{ fontSize: "12pt" }}>
                      Delivery Date
                    </Typography>
                    <BootstrapInput
                      value={deliveryDate}
                      sx={{ width: "100%" }}
                      //   defaultValue="react-bootstrap"
                      placeholder="Please enter text"
                      type="date"
                      size="small"
                      id="bootstrap-input"
                      onChange={handleDateChange}
                    />
                  </Stack>
                </Grid>
                {/* <Grid item xs={6}>
                <Stack>
                  <Typography variant="p" sx={{ fontSize: "12pt" }}>
                    Total Pay
                  </Typography>
                  <BootstrapInput
                    value={service.service_price}
                    placeholder="Please enter text"
                    type="email"
                    size="small"
                    id="bootstrap-input"
                  />
                </Stack>
              </Grid> */}
                <Grid item xs={12}>
                  <Stack>
                    <TextareaAutosize
                      aria-label="minimum height"
                      value={message}
                      minRows={3}
                      placeholder="Enter message"
                      id="bootstrap-input"
                      style={{ width: "90%" }}
                      onChange={handleMessage}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"right"}
                sx={{ width: "88%" }}
              >
                <Button
                  variant="contained"
                  sx={{ marginY: "20px" }}
                  onClick={() => {
                    setToggle(false);
                  }}
                >
                  Next
                </Button>
              </Stack>
            </Box>
            <Box className={toggle ? "hideForm" : ""}>
              <Box sx={{ padding: "20px" }}>
                <Paper sx={{ padding: "15px" }} elevation={5}>
                  <Stack spacing={1}>
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      alignItems={"center"}
                    >
                      <Typography
                        variant="p"
                        sx={{ fontSize: "14pt", fontWeight: 600 }}
                      >
                        {fetchedName}
                      </Typography>
                      <Button
                        startIcon={<Edit />}
                        sx={{ textTransform: "capitalize" }}
                        onClick={() => {
                          setToggle(true);
                        }}
                      >
                        Edit
                      </Button>
                    </Stack>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "11pt", fontWeight: 500 }}
                    >
                      {fetchedAddress}, {fetchedCountry}
                    </Typography>
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      justifyContent="space-between"
                      alignItems={"center"}
                    >
                      <Stack justifyContent="center" alignItems={"flex-start"}>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "10pt", fontWeight: 500 }}
                        >
                          <strong> Phone:</strong> {fetchedPhone}
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "10pt", fontWeight: 500 }}
                        >
                          <strong> Service Start Date:</strong>{" "}
                          {serviceStartDate}
                        </Typography>
                      </Stack>
                      <Stack justifyContent="center" alignItems={"flex-start"}>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "10pt", fontWeight: 500 }}
                        >
                          <strong> Email: </strong>
                          {fetchedEmail}
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "10pt", fontWeight: 500 }}
                        >
                          <strong> Delivery Date:</strong> {deliveryDate}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "11pt", fontWeight: 500 }}
                    >
                      <strong> Message: </strong>
                      {message}
                    </Typography>
                  </Stack>
                </Paper>
                <PaymentMethod
                  gatewayCharge={gatewayChargeList}
                  handleGatewayCharge={handleGatewayCharge}
                  grandTotal={grandTotal}
                  service_refer={service.service_refer}
                />
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            sx={{
              padding: "15px",
              bgcolor: "#14506A",
              color: "white",
              height: "100%",
            }}
          >
            <Stack spacing={1}>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="p">Item</Typography>
                <Typography variant="p">{items.length}</Typography>
              </Stack>

              {/* <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="p">Quantity</Typography>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <IconButton
                    aria-label="delete"
                    size="medium"
                    sx={{ color: "#da00ff" }}
                    onClick={reduceQuantity}
                  >
                    <Remove fontSize="inherit" />
                  </IconButton>
                  <Box
                    sx={{
                      border: "2px solid lightblue",
                      borderRadius: "10px",
                      paddingX: "10px",
                      paddingy: "5px",
                    }}
                  >
                    <Typography variant="p">{quantity}</Typography>
                  </Box>
                  <IconButton
                    aria-label="delete"
                    size="medium"
                    sx={{ color: "#00fd0a" }}
                    onClick={addQuantity}
                  >
                    <Add fontSize="inherit" />
                  </IconButton>
                </Stack>
              </Stack> */}
              {/* <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="p">Service Fee- (10%)</Typography>
                <Typography variant="p">12 BDT</Typography>
              </Stack> */}

              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="p">Total Price</Typography>
                <Typography variant="p">BDT {total}</Typography>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="p">Gateway Charge</Typography>
                <Typography variant="p">
                  {((gatewayCharge / 100) * total).toFixed(2)}
                </Typography>
              </Stack>
              <Divider />
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="p">Total Amount</Typography>
                <Typography variant="p">{grandTotal.toFixed(2)}</Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Booknow;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
