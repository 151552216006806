import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const pages = [
  "হোম",
  "বই",
  "সাধারন বই",
  "বিষয়",
  "লেখক",
  "প্রকাশক",
  "বইমেলা",
  "প্রি-অর্ডার",
  "প্রসাধনী",
  "পোশাক",
  "জেনারেল",
  "যোগাযোগ",
];

const ResponsiveAppBar = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClick = (path) => {
    if (path === "হোম") {
      navigate("/");
    } else if (path === "বই") {
      navigate("/book");
    } else if (path === "সাধারন বই") {
      navigate("/generalbook");
    } else if (path === "বিষয়") {
      navigate("/subject");
    } else if (path === "লেখক") {
      navigate("/writer");
    } else if (path === "প্রকাশক") {
      navigate("/publisher");
    } else if (path === "বইমেলা") {
      navigate("/bookfair");
    } else if (path === "প্রি-অর্ডার") {
      navigate("/preorder");
    } else if (path === "প্রসাধনী") {
      navigate("/cosmetic");
    } else if (path === "পোশাক") {
      navigate("/clothings");
    } else if (path === "জেনারেল") {
      navigate("/general");
    } else if (path === "যোগাযোগ") {
      navigate("/contact");
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "#f8f9fa",
        border: "1px solid #dee2e6",
        display: { xs: "none", sm: "none", md: "block" },
      }}
    >
      <Container>
        <AppBar
          elevation={0}
          position="static"
          sx={{ backgroundColor: "white", color: "black" }}
        >
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center" sx={{ color: "black" }}>
                      {page}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "space-between",
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => {
                    handleClick(page);
                  }}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    color: "black",
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
      </Container>
    </Box>
  );
};
export default ResponsiveAppBar;
