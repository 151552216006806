import { Box, Container, Toolbar } from '@mui/material';
import React from 'react';
import ReviewLeft from './ReviewLeft';
import ReviewRight from './ReviewRight';

const Review = () => {
    return (
        <Container>
            <Toolbar>
                <ReviewLeft sx={{width: '70%', bgcolor: 'green'}}/>
                <ReviewRight sx={{width: '30%', bgcolor: 'red'}} />
            </Toolbar>
            
        </Container>
    );
};

export default Review;