import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import { Container, Stack } from "@mui/system";
import * as React from "react";

import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Translate,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Bkash from "../../images/bkash.png";
import Rocket from "../../images/rocket.png";
import Nagad from "../../images/nagad.png";
import Upay from "../../images/upay.png";
import Paypal from "../../images/paypal2.png";
import Wallet from "../../images/wallet.png";
import cod from "../../images/cod.png";
import Ghorami from "../../images/ghorami.ico";
import styled from "styled-components";
import { alltotalContext } from "../../App";
import { useState } from "react";
import { useContext } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PaymentMethodLeft = () => {
  const initialDetails = {
    number: "",
    transactionId: "",
    delivaryDate: "",
  };
  const [paymentDetails, setPaymentDetails] = useState(initialDetails);
  const [allTotal, setAllTotal] = useContext(alltotalContext);
  const [activeTab, setActiveTab] = useState("");
  const [alert, setAlert] = useState(0);
  const [paymentType, setPaymentType] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    if (!activeTab) {
      setAlert(1);
    } else {
      setAlert(0);
    }
  };
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    const updatePaymentDetails = paymentDetails;
    updatePaymentDetails[event.target.name] = event.target.value;
    updatePaymentDetails.fieldErrorAlert = "";
    setPaymentDetails({ ...updatePaymentDetails });
  };
  console.log("paymentDetails", paymentDetails);

  return (
    <Stack
      spacing={1}
      justifyContent=""
      alignItems="center"
      sx={{
        width: { xs: "100%", md: "67%" },
        padding: { xs: 0, sm: "15px" },
        marginTop: "40px",
      }}
    >
      <PaymentMethodWrapper>
        <Container>
          {alert === 1 && (
            <Alert
              severity="warning"
              onClose={() => {
                setAlert(0);
              }}
              sx={{ marginBottom: "5%", marginTop: "-6%" }}
            >
              Must choose a payment method
            </Alert>
          )}
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              sx={{ "&:hover": { color: "white" } }}
              onClick={() => {
                setPaymentType(0);
              }}
              className={paymentType === 0 ? "buttonActive" : ""}
            >
              COD
            </Button>
            <Button
              variant="contained"
              sx={{ "&:hover": { color: "white" } }}
              onClick={() => {
                setPaymentType(1);
              }}
              className={paymentType === 1 ? "buttonActive" : ""}
            >
              Wallet
            </Button>
            <Button
              variant="contained"
              sx={{ "&:hover": { color: "white" } }}
              onClick={() => {
                setPaymentType(2);
              }}
              className={paymentType === 2 ? "buttonActive" : ""}
            >
              International
            </Button>
          </Stack>

          {activeTab && (
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Payment Method : {activeTab}
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Total Payable Amount : BDT {allTotal}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Number:
                </Typography>
                <OutlinedInput
                  placeholder="Please enter text"
                  type="number"
                  name="number"
                  size="small"
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                />
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Transaction Id:
                </Typography>
                <OutlinedInput
                  placeholder="Please enter text"
                  type="text"
                  name="transactionId"
                  size="small"
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                />
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Expected Delivary Date:
                </Typography>
                <OutlinedInput
                  placeholder="Please enter text"
                  type="date"
                  name="delivaryDate"
                  size="small"
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginTop: "20px" }}
                >
                  <Button
                    variant="contained"
                    onClick={handleClose}
                    sx={{ bgcolor: "gray" }}
                  >
                    Cancle
                  </Button>
                  {paymentDetails.number &&
                    paymentDetails.transactionId &&
                    paymentDetails.delivaryDate && (
                      <Button variant="contained">Confirm</Button>
                    )}
                  {(!paymentDetails.number ||
                    !paymentDetails.transactionId ||
                    !paymentDetails.delivaryDate) && (
                    <Button variant="contained" disabled>
                      Confirm
                    </Button>
                  )}
                </Stack>
              </Box>
            </Modal>
          )}

          {paymentType === 0 && (
            <Stack
              direction="row"
              spacing={0}
              sx={{ width: { xs: "100%", md: "67%" }, marginTop: "20px" }}
              justifyContent="left"
              alignItems="center"
            >
              <Box
                component="img"
                sx={{
                  padding: "0 12px",
                  width: "20%",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "TranslateY(-5px)",
                    transition: "all .5s",
                  },
                }}
                className={activeTab === "Bkash" ? "active" : ""}
                onClick={() => {
                  setActiveTab("Bkash");
                }}
                alt="The house from the offer."
                src={Bkash}
              />

              <Box
                component="img"
                sx={{
                  padding: "0 12px",
                  width: "20%",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "TranslateY(-5px)",
                    transition: "all .5s",
                  },
                }}
                className={activeTab === "Rocket" ? "active" : ""}
                onClick={() => {
                  setActiveTab("Rocket");
                }}
                alt="The house from the offer."
                src={Rocket}
              />
            </Stack>
          )}
          {paymentType === 1 && (
            <Stack
              direction="row"
              spacing={0}
              sx={{ width: { xs: "100%", md: "67%" }, marginTop: "20px" }}
              justifyContent="left"
              alignItems="center"
            >
              <Box
                component="img"
                sx={{
                  padding: "0 12px",
                  width: { xs: "15%", md: "20%" },
                  cursor: "pointer",
                  "&:hover": {
                    transform: "TranslateY(-5px)",
                    transition: "all .5s",
                  },
                }}
                className={activeTab === "Bkash" ? "active" : ""}
                onClick={() => {
                  setActiveTab("Bkash");
                }}
                alt="The house from the offer."
                src={Bkash}
              />
              <Box
                component="img"
                sx={{
                  padding: "0 12px",
                  width: { xs: "15%", md: "20%" },
                  cursor: "pointer",
                  "&:hover": {
                    transform: "TranslateY(-5px)",
                    transition: "all .5s",
                  },
                }}
                className={activeTab === "Rocket" ? "active" : ""}
                onClick={() => {
                  setActiveTab("Rocket");
                }}
                alt="The house from the offer."
                src={Rocket}
              />
              <Box
                component="img"
                sx={{
                  padding: "0 12px",
                  width: { xs: "15%", md: "20%" },
                  cursor: "pointer",
                  "&:hover": {
                    transform: "TranslateY(-5px)",
                    transition: "all .5s",
                  },
                }}
                className={activeTab === "Nagad" ? "active" : ""}
                onClick={() => {
                  setActiveTab("Nagad");
                }}
                alt="The house from the offer."
                src={Nagad}
              />
              <Box
                component="img"
                sx={{
                  padding: "12px 12px",
                  width: { xs: "15%", md: "18%" },
                  // borderRadius: '50%',
                  cursor: "pointer",
                  "&:hover": {
                    transform: "TranslateY(-5px)",
                    transition: "all .5s",
                  },
                }}
                className={activeTab === "Upay" ? "active" : ""}
                onClick={() => {
                  setActiveTab("Upay");
                }}
                alt="The house from the offer."
                src={Upay}
              />
              <Box
                component="img"
                sx={{
                  padding: "12px 12px",
                  width: { xs: "15%", md: "20%" },
                  // borderRadius: '50%',
                  cursor: "pointer",
                  "&:hover": {
                    transform: "TranslateY(-5px)",
                    transition: "all .5s",
                  },
                }}
                className={activeTab === "Wallet" ? "active" : ""}
                onClick={() => {
                  setActiveTab("Wallet");
                }}
                alt="The house from the offer."
                src={Wallet}
              />
            </Stack>
          )}
          {paymentType === 2 && (
            <Stack
              direction="row"
              spacing={0}
              sx={{ width: { xs: "100%", md: "67%" }, marginTop: "20px" }}
              justifyContent="left"
              alignItems="center"
            >
              <Box
                component="img"
                sx={{
                  padding: "12px 12px",
                  width: "15%",
                  // height: '100%',
                  // borderRadius: '100%',
                  // border: '1px solid grey',
                  cursor: "pointer",
                  "&:hover": {
                    transform: "TranslateY(-5px)",
                    transition: "all .5s",
                  },
                }}
                className={activeTab === "Paypal" ? "active" : ""}
                onClick={() => {
                  setActiveTab("Paypal");
                }}
                alt="The house from the offer."
                src={Paypal}
              />
              <Box
                component="img"
                sx={{
                  padding: "12px 12px",
                  width: "15%",
                  height: "15%",
                  // borderRadius: '50%',
                  cursor: "pointer",
                  "&:hover": {
                    transform: "TranslateY(-5px)",
                    transition: "all .5s",
                  },
                }}
                className={activeTab === "Ghorami" ? "active" : ""}
                onClick={() => {
                  setActiveTab("Ghorami");
                }}
                alt="The house from the offer."
                src={Ghorami}
              />
            </Stack>
          )}
          <Stack direction="row" justifyContent="left" alighItems="center">
            <Button
              variant="contained"
              sx={{ marginTop: "10%", bgcolor: "black" }}
              onClick={handleOpen}
            >
              Confirm Method
            </Button>
          </Stack>
        </Container>
      </PaymentMethodWrapper>
    </Stack>
  );
};

export default PaymentMethodLeft;

const PaymentMethodWrapper = styled.div`
  .active {
    background: linear-gradient(35deg, #494949, #313131);
    color: white;
  }
  .buttonActive {
    background-color: tomato;
    color: black;
  }
`;
