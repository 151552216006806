import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CreditCard, StayCurrentPortrait } from "@mui/icons-material";
import { FaPaypal } from "react-icons/fa";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Toolbar,
} from "@mui/material";
import { Stack } from "@mui/system";
import masterCardLogo from "../../images/mastercard.png";
import americanExpress from "../../images/american-express.png";
import visa from "../../images/visa.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabPayment = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ border: "1px solid grey", borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            icon={<CreditCard />}
            iconPosition="start"
            label="Credit Card"
            {...a11yProps(0)}
            sx={{ color: "blue" }}
          />

          <Tab
            icon={<FaPaypal />}
            iconPosition="start"
            label="Paypal"
            {...a11yProps(1)}
            sx={{ color: "blue" }}
          />
          <Tab
            icon={<StayCurrentPortrait />}
            iconPosition="start"
            label="Net Banking"
            {...a11yProps(2)}
            sx={{ color: "blue" }}
          />
        </Tabs>
      </Box>
      <Box sx={{ marginTop: "16px", border: "1px solid grey" }}>
        <TabPanel value={value} index={0}>
          <Stack spacing={2}>
            <Stack>
              <Typography variant="h6">Zip/Postal Code</Typography>
              <FormControl sx={{ width: "100%" }}>
                <OutlinedInput
                  placeholder=""
                  size="small"
                  sx={{ borderRadius: 0 }}
                />
              </FormControl>
            </Stack>
            <Stack>
              <Typography variant="h6">Card Number</Typography>
              <FormControl sx={{ width: "100%" }}>
                <Stack direction="row">
                  <OutlinedInput
                    placeholder=""
                    size="small"
                    sx={{ width: "80%", borderRadius: 0 }}
                  />
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      height: 40,
                      width: 61,
                      bgcolor: "#CED4DA",
                      border: "1px solid grey",
                      marginLeft: "-1px",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 21,
                        width: 35,
                      }}
                      alt="The house from the offer."
                      src={masterCardLogo}
                    />
                  </Stack>
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      height: 40,
                      width: 61,
                      bgcolor: "#CED4DA",
                      border: "1px solid grey",
                      marginLeft: "-1px",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 11,
                        width: 35,
                      }}
                      alt="The house from the offer."
                      src={visa}
                    />
                  </Stack>
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      height: 40,
                      width: 61,
                      bgcolor: "#CED4DA",
                      border: "1px solid grey",
                      marginLeft: "-1px",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 11,
                        width: 35,
                      }}
                      alt="The house from the offer."
                      src={americanExpress}
                    />
                  </Stack>
                </Stack>
              </FormControl>
            </Stack>
            <Stack direction="row">
              <Stack sx={{ width: "65%" }}>
                <Typography variant="h6">Expiration Date</Typography>
                <FormControl sx={{ width: "100%" }}>
                  <Stack direction="row">
                    <OutlinedInput
                      placeholder="MM"
                      size="small"
                      sx={{ borderRadius: 0 }}
                    />
                    <OutlinedInput
                      placeholder="YY"
                      size="small"
                      sx={{ borderRadius: 0, marginLeft: "-1px" }}
                    />
                  </Stack>
                </FormControl>
              </Stack>
              <Stack sx={{ width: "35%" }}>
                <Typography variant="h6">CVV</Typography>
                <FormControl sx={{ width: "100%" }}>
                  <OutlinedInput
                    placeholder="Three digit CVV number"
                    size="small"
                    sx={{ borderRadius: 0 }}
                  />
                </FormControl>
              </Stack>
            </Stack>
            <Button variant="contained" sx={{ bgcolor: "black" }}>
              Confirm payment
            </Button>
          </Stack>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Stack>
            <Typography variant="h6">
              Select your Paypal Account type
            </Typography>
            <Stack direction="row">
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Domestic"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="International"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
            <Button
              variant="outlined"
              startIcon={<FaPaypal />}
              sx={{ color: "black", width: "30%" }}
            >
              Login to my Paypal
            </Button>
            <Typography variant="h6">
              Note: After clicking on the button, you will be directed to a
              secure gateway for payment. After completing the payment process,
              you will be redirected back to the website to view details of your
              order.
            </Typography>
          </Stack>
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
      </Box>
    </Box>
  );
};

export default TabPayment;
