import * as React from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, IconButton, Modal, Rating, Stack, Toolbar } from "@mui/material";
import {
  AddShoppingCart,
  ShoppingCart,
  Star,
  ZoomIn,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { CartContext, PDstoragedContext } from "../../../App";
import { useCart } from "react-use-cart";
import BookOverview from "./BookOverview";
import ClothOverview from "./ClothOverview";
import CosmeticOverview from "./CosmeticOverview";
import "./BookCard.css";

const BookCard = (props) => {
  const { data } = props;
  const {
    product_refer,
    product_name,
    price,
    currency,
    pro_pic,
    pro_pic_2,
    category,
    sub_category,
    discount,
    type,
  } = data;

  const [open, setOpen] = useState(false);
  let discountedPrice =
    parseFloat(price) - parseFloat(price) * (parseFloat(discount) / 100);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  let count = 0;
  const [hoverOnPic, sethoverOnPic] = useState(0);
  const [hoverOnCard, sethoverOnCard] = useState(0);

  const navigate = useNavigate();

  const { addItem } = useCart();

  const addToCart = (data) => {
    console.log(data);
    addItem(data);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Card
      sx={{
        width: { xs: "95%", sm: "95%", md: "95%" },
        height: "auto",
        marginLeft: { xs: "4px", sm: "6px", md: "8px" },
        // padding: "3px",
        position: "relative",
        // "&:hover": {
        //   height: "96%",
        //   transition: "all .5s",
        // },
      }}
      onMouseEnter={() => {
        sethoverOnCard(1);
      }}
      onMouseLeave={() => {
        sethoverOnCard(0);
      }}
    >
      {/* <Link to={"/productdetails"}> */}
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", margin: "auto" }}
      >
        {hoverOnPic === 0 ? (
          <Box
            component="img"
            sx={{
              // height: '100%',
              width: "100%",
              height: "auto",
              objectFit: "cover",
            }}
            alt="The house from the offer."
            src={pro_pic}
            onMouseEnter={() => {
              sethoverOnPic(1);
            }}
          />
        ) : (
          <Box
            component="img"
            sx={{
              // height: '100%',
              width: "100%",
              height: "auto",
              objectFit: "cover",
            }}
            alt="The house from the offer."
            src={pro_pic_2}
            onMouseLeave={() => {
              sethoverOnPic(0);
            }}
            // onClick={() => handlePDstorage(product_refer)}
          />
        )}
      </Stack>
      {/* </Link> */}
      <CardContent>
        <Box sx={{ height: "30px" }}>
          <Typography
            gutterBottom
            variant="caption"
            component="div"
            sx={{ fontSize: "10pt", fontWeight: 700, lineHeight: "15px" }}
            onClick={() => {
              navigate("/productdetails-" + product_refer + "-" + type);
            }}
          >
            {product_name}
          </Typography>
        </Box>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems="center"
          sx={{}}
        >
          <Typography variant="p" sx={{ fontSize: "9pt", fontWeight: 400 }}>
            {category}, {sub_category}
          </Typography>
          <Stack
            justifyContent={"center"}
            alignItems="center"
            // sx={{ marginLeft: "-23px" }}
          >
            <Typography
              variant="p"
              sx={{
                textDecoration: "line-through",
                fontSize: "10pt",
                color: "darkred",
                fontWeight: 400,
              }}
            >
              {currency} {price}
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontSize: "10pt",
                fontWeight: 700,
                color: "darkgreen",
              }}
            >
              {currency} {discountedPrice}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ paddingX: "15px", paddingBottom: "10px", marginTop: "-10px" }}
      >
        <Box
          className="btnParentBox"
          sx={{
            width: "10%",
            height: "40px",
            transition: "width 0.5s",
          }}
        >
          <Button
            variant="contained"
            className="btnContainer"
            size="small"
            sx={{
              width: "95%",
              minWidth: "43px",
              height: "40px",
              padding: "0px",
              backgroundColor: "#24AEB1",
              fontSize: "9pt",
              "&:hover": {
                backgroundColor: "#24AEB1",
              },
              // paddingY: "2px",
            }}
            onClick={() => addToCart(data)}
          >
            {/* <Stack
              direction={"row"}
              justifyContent={"Left"}
              alignItems="center"
            > */}
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "0%",
              }}
            >
              <ShoppingCart
                className="btnIcon"
                sx={{
                  fontSize: "14pt",
                }}
              />
            </span>
            <span
              className="btnWritten"
              style={{
                padding: 0,
                // margin: 0,
                marginLeft: "10px",
                marginRight: "-10px",
                width: 0,
                opacity: 0,
                overflow: "hidden",
                fontSize: "8pt",
              }}
            >
              Add to cart
            </span>
            {/* </Stack> */}
          </Button>
        </Box>

        {/* <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems="center"
          // variant="contained"
          // size="small"
          // elevation={0}
          sx={{
            width: "30%",
            margin: "5px",
            color: "black",
            cursor: "pointer",
            backgroundColor: "white",
            borderRadius: "30px",
          }}
          onClick={handleOpen}
        >
          <ZoomIn />
        </Stack> */}
        <Box sx={{ width: "30%" }}>
          <IconButton color="primary" onClick={handleOpen}>
            <ZoomIn />
          </IconButton>
        </Box>
      </Stack>
      <Stack
        bgcolor="#f23534"
        sx={{
          width: { xs: "50px", md: "50px" },
          height: { xs: "50px", md: "50px" },
          padding: "10px 0px 0px 20px",
          borderRadius: "22%",
          position: "absolute",
          top: "-12px",
          left: "-22px",
        }}
        justifyContent="center"
        alignItems={"center"}
      >
        <Typography
          variant="caption"
          sx={{ fontSize: { xs: "7pt", sm: "8pt" }, color: "white" }}
        >
          {discount}
        </Typography>
        <Typography
          variant="caption"
          sx={{ fontSize: { xs: "7pt", sm: "8pt" }, color: "white" }}
        >
          OFF
        </Typography>
      </Stack>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {category === "BOOK" && (
            <BookOverview productDetails={data} handleClose={handleClose} />
          )}
          {category === "CLOTH" && (
            <ClothOverview productDetails={data} handleClose={handleClose} />
          )}
          {category === "COSMETIC" && (
            <CosmeticOverview productDetails={data} handleClose={handleClose} />
          )}
        </Box>
      </Modal>
    </Card>
  );
};

export default BookCard;
