import { Call, Language } from "@mui/icons-material";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { borderRadius, Box, Stack } from "@mui/system";
import React from "react";
import playstorelogo from "../../../images/playStore.png";
import appstorelogo from "../../../images/appStore.png";

const AppDownload = () => {
  return (
    <Box>
      <Paper sx={{ padding: "50px", paddingBottom: "10px" }} elevation={1}>
        <Grid
          container
          rowSpacing={{ xs: 4, md: 2 }}
          spacing={0}
          sx={{ marginLeft: "50px" }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Box>
              <Stack
                spacing={2}
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
              >
                <Stack
                  direction={"row"}
                  justifyContent="right"
                  alignItems={"center"}
                  sx={{
                    width: "15%",
                    height: "37px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                >
                  <Paper
                    sx={{ borderRadius: "50%", width: "60px", height: "60px" }}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent="center"
                      alignItems={"center"}
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "white",
                        borderRadius: "50%",
                      }}
                    >
                      {/* <Box
            component="img"
            sx={{
              // padding: "0 12px",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
            }}
            alt="The house from the offer."
            //   src={logo}
          /> */}
                      <Language
                        sx={{
                          fontSize: "25pt",
                          color: "#3ecfcb",
                        }}
                      />
                    </Stack>
                  </Paper>
                </Stack>
                <Stack sx={{}}>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "12pt", fontWeight: 700 }}
                  >
                    আমাদের ঠিকানা
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "10pt", fontWeight: 500, color: "gray" }}
                  >
                    বায়তুল মোকাররম মার্কেট, পুরানা পল্টন, ঢাকা।{" "}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box>
              <Stack
                spacing={2}
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
              >
                <Stack
                  direction={"row"}
                  justifyContent="right"
                  alignItems={"center"}
                  sx={{
                    width: "15%",
                    height: "37px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                >
                  <Paper
                    sx={{ borderRadius: "50%", width: "60px", height: "60px" }}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent="center"
                      alignItems={"center"}
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "white",
                        borderRadius: "50%",
                      }}
                    >
                      <Call
                        sx={{
                          fontSize: "25pt",
                          color: "#3ecfcb",
                        }}
                      />
                    </Stack>
                  </Paper>
                </Stack>
                <Stack sx={{}}>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "12pt", fontWeight: 700 }}
                  >
                    হোয়াটসঅ্যাপ করুন
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "15pt", fontWeight: 500, color: "#3ecfcb" }}
                  >
                    01786-223347
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box>
              <Stack
                // direction={"row"}
                justifyContent="space-between"
                alignItems={{ xs: "center", md: "flex-start" }}
                sx={{
                  marginTop: { xs: "0px", md: "-20px" },
                  marginLeft: { xs: "-120px", md: "0" },
                }}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "12pt", fontWeight: 700 }}
                >
                  অ্যাপ ইনস্টল করুন এখনই
                </Typography>
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent="left"
                  alignItems={"center"}
                  sx={{ marginTop: "-30px" }}
                >
                  {" "}
                  <Box
                    component="img"
                    sx={{
                      width: 120,
                    }}
                    alt="The house from the offer."
                    src={appstorelogo}
                  />{" "}
                  <Box
                    component="img"
                    sx={{
                      width: 110,
                    }}
                    alt="The house from the offer."
                    src={playstorelogo}
                  />
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default AppDownload;
