import {
  AutoStories,
  AutoStoriesRounded,
  CheckroomRounded,
  Diversity3,
  LocalConvenienceStore,
  LocalShipping,
  Payment,
  SaveAsRounded,
  Security,
} from "@mui/icons-material";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { borderRadius, Box, Stack } from "@mui/system";
import React from "react";
import { GiLipstick } from "react-icons/gi";

const LowerBannerFooter = () => {
  return (
    // <Container sx={{ zIndex: 5 }}>
    <Paper sx={{ padding: "25px", bgcolor: "#E8EFED" }} elevation={0}>
      <Grid
        container
        rowSpacing={3}
        spacing={2}
        // marginLeft={{ xs: 0, md: "23px" }}
      >
        <Grid item xs={6} md={2.4}>
          <Box>
            <Stack
              spacing={{ xs: 1.3, md: 0 }}
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack
                direction={"row"}
                justifyContent="center"
                alignItems={"center"}
                sx={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "#24aeb1",
                  borderRadius: "50%",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#1d77bb",
                  },
                }}
              >
                <LocalShipping
                  sx={{
                    color: "white",
                  }}
                />
              </Stack>
              <Stack sx={{ width: "75%" }}>
                <Typography
                  variant="p"
                  sx={{ fontSize: "11pt", fontWeight: 700 }}
                >
                  দ্রুত ডেলিভারি
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontSize: "10pt", fontWeight: 500, color: "gray" }}
                >
                  ৫০০ টাকার উপর অর্ডারে
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={6} md={2.4}>
          <Box>
            <Stack
              spacing={{ xs: 1.3, md: 0 }}
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack
                direction={"row"}
                justifyContent="center"
                alignItems={"center"}
                sx={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "#eb3a7b",
                  borderRadius: "50%",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#1d77bb",
                  },
                }}
              >
                <Payment
                  style={{
                    color: "white",
                  }}
                />
              </Stack>
              <Stack sx={{ width: "75%" }}>
                <Typography
                  variant="p"
                  sx={{ fontSize: "11pt", fontWeight: 700 }}
                >
                  অনলাইন পেমেন্ট
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontSize: "10pt", fontWeight: 500, color: "gray" }}
                >
                  ১০০% নিরাপদ পেমেন্ট
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={6} md={2.4}>
          <Box>
            <Stack
              spacing={{ xs: 1.3, md: 0 }}
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack
                direction={"row"}
                justifyContent="center"
                alignItems={"center"}
                sx={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "#00d084",
                  borderRadius: "50%",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#1d77bb",
                  },
                }}
              >
                <Security
                  sx={{
                    color: "white",
                  }}
                />
              </Stack>
              <Stack sx={{ width: "75%" }}>
                <Typography
                  variant="p"
                  sx={{ fontSize: "11pt", fontWeight: 700 }}
                >
                  কেনাকাটা করুন নিশ্চিন্তে
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontSize: "10pt", fontWeight: 500, color: "gray" }}
                >
                  যদি কোন পণ্যে সমস্যা হয়
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={6} md={2.4}>
          <Box>
            <Stack
              spacing={{ xs: 1.3, md: 0 }}
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack
                direction={"row"}
                justifyContent="center"
                alignItems={"center"}
                sx={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "#51acf6",
                  borderRadius: "50%",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#1d77bb",
                  },
                }}
              >
                <LocalConvenienceStore
                  sx={{
                    color: "white",
                  }}
                />
              </Stack>
              <Stack sx={{ width: "75%" }}>
                <Typography
                  variant="p"
                  sx={{ fontSize: "11pt", fontWeight: 700 }}
                >
                  ২৪/৭ হেল্প সেন্টার
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontSize: "10pt", fontWeight: 500, color: "gray" }}
                >
                  সার্বক্ষণিক সাপোর্ট সিস্টেম
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={6} md={2.4}>
          <Box>
            <Stack
              spacing={{ xs: 1.3, md: 0 }}
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack
                direction={"row"}
                justifyContent="center"
                alignItems={"center"}
                sx={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "darkgreen",
                  borderRadius: "50%",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#1d77bb",
                  },
                }}
              >
                <Diversity3
                  sx={{
                    color: "white",
                  }}
                />
              </Stack>
              <Stack sx={{ width: "75%" }}>
                <Typography
                  variant="p"
                  sx={{ fontSize: "11pt", fontWeight: 700 }}
                >
                  বন্ধুত্বপূর্ণ সার্ভিস
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontSize: "10pt", fontWeight: 500, color: "gray" }}
                >
                  ৩০ দিনের গ্যারান্টি
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Paper>
    // </Container>
  );
};

export default LowerBannerFooter;
