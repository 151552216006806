import {
  ArrowForwardIos,
  CheckCircle,
  Close,
  Favorite,
  Flag,
  HelpRounded,
  LocalOffer,
  LocationOn,
  OpenInNew,
  Psychology,
  Remove,
  Schedule,
  WatchLater,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Rating,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import BookTypeList from "./BookTypeList";
import MinimumOrderList from "./MinimumOrderList";
import OfferTypeList from "./OfferTypeList";
import PublisherList from "./PublisherList";
import "./ResFilterModal.css";
import SliderPriceRange from "./SliderPriceRange";
import SubCategoryList from "./SubCategoryList";
import WriterList from "./WriterList";

const ResBookFilterModal = (props) => {
  const navigate = useNavigate();
  const { handleClose, style, open } = props;

  return (
    <Paper sx={style} className={open ? "showModal" : ""}>
      <Box
        sx={{
          position: "relative",
          paddingX: "10px",
          marginTop: "20px",
        }}
      >
        <Box sx={{ paddingY: "8px" }}>
          <Typography variant="p" sx={{ fontSize: "15pt", fontWeight: 700 }}>
            Filter by
          </Typography>{" "}
        </Box>
        <Stack>
          <SubCategoryList />
          <Divider light sx={{ marginY: "10px" }} />
          <BookTypeList />

          <Divider light sx={{ marginY: "10px" }} />

          <Typography variant="p" sx={{ fontSize: "13pt", fontWeight: 600 }}>
            মূল্য
          </Typography>
          <SliderPriceRange />

          <Divider light sx={{ marginY: "10px" }} />

          <Typography variant="p" sx={{ fontSize: "13pt", fontWeight: 600 }}>
            লেখক
          </Typography>
          <WriterList />

          <Divider light sx={{ marginY: "10px" }} />

          <Typography variant="p" sx={{ fontSize: "13pt", fontWeight: 600 }}>
            প্রকাশনী
          </Typography>
          <PublisherList />

          <Divider light sx={{ marginY: "10px" }} />

          <Typography variant="p" sx={{ fontSize: "13pt", fontWeight: 600 }}>
            অফারটাইপ
          </Typography>
          <OfferTypeList />
          <Divider light sx={{ marginY: "10px" }} />

          <Typography variant="p" sx={{ fontSize: "13pt", fontWeight: 600 }}>
            সর্বনিম্ন অর্ডার
          </Typography>
          <MinimumOrderList />
        </Stack>

        <IconButton
          aria-label="delete"
          onClick={handleClose}
          className={open ? "showCancleButton" : ""}
          sx={{
            bgcolor: "white",
            position: "fixed",
            right: -40,
            top: "130px",
            zIndex: 10,
            width: "40px",
            height: "40px",
            transition: "all .8s",
          }}
          size="small"
        >
          <Stack
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
          >
            <Close />
          </Stack>
        </IconButton>
        <Stack
          sx={{
            width: "100%",

            padding: "10px",
            bgcolor: "white",
            position: "fixed",
            bottom: "-100%",
          }}
          className={open ? "showApplyButton" : ""}
          direction="row"
          justifyContent={"right"}
          alignItems="center"
          spacing={1}
        >
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleClose}>
            Apply
          </Button>
          <Box
            sx={{
              width: "30px",
            }}
          ></Box>
        </Stack>
      </Box>
    </Paper>
  );
};

export default ResBookFilterModal;
