import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getRemainingTimeUntillMsTimestamp } from "./CountdownTimerUtils";

const defaultRemainingTime = {
  seconds: "00",
  minutes: "00",
  hours: "00",
  days: "00",
};
const CountDownTimer = ({ countdownTimestampMs }) => {
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(countdownTimestampMs);
    }, 1000);
    return () => clearTimeout(intervalId);
  }, []);

  function updateRemainingTime(countdown) {
    getRemainingTimeUntillMsTimestamp(countdown);
  }
  return <div></div>;
};

export default CountDownTimer;
