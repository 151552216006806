import { Cached, HighlightOff, ShoppingBag } from "@mui/icons-material";
import {
  Button,
  Container,
  Divider,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { bgcolor, Box, Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import { alltotalContext } from "../../App";
// import { ItemCountContext, totalContext } from "../../App";
const CartRight = () => {
  const navigate = useNavigate();
  const [age, setAge] = React.useState("");
  const { items, cartTotal } = useCart();
  // const [allTotal, setAllTotal] = useContext(alltotalContext);
  // console.log('allTotal:', allTotal)

  // const [cart, setCart] = useState([])
  //   useEffect(()=>{
  //   setCart(JSON.parse(localStorage.getItem('cart')))
  // },[cart])
  // let subTotal = 0

  //     subTotal = allTotal.reduce((total, product) => total + product,0);

  // const subTotal = cart.reduce(
  //   (total, product) => total + parseFloat(itemCount) * parseFloat(product.price),
  //   0
  // );
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleClick = () => {
    // navigate("/checkout");
    navigate("/booknow");
  };
  console.log(items);
  let total = 0;
  let shipping = 0;
  let quantity = 0;
  for (const product of items) {
    quantity = quantity + product.quantity;
    total = total + product.price * product.quantity;
    // shipping = shipping + product.shipping * product.shipping;
    // shipping = shipping + product.shipping * product.shipping;
  }
  const tax = parseFloat((total * 0.05).toFixed(2)); //5 % tax
  console.log(tax);
  const shippingTotal = parseFloat((items.length * 60).toFixed(2)); //60taka shipping cost
  console.log(shippingTotal);
  const grandTotal = total + tax + shippingTotal; //option 2

  return (
    <Stack
      sx={{ width: { xs: "100%", md: "33%" }, padding: { xs: 0, sm: "15px" } }}
    >
      <Stack
        spacing={1}
        sx={{
          // border: "1px solid gray",
          padding: "15px",
          marginTop: "-1px",
        }}
      >
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">Subtotal:</Typography>
            <Typography variant="h6">BDT {cartTotal}</Typography>
          </Stack>
          <Divider />
          <Button
            variant="contained"
            size="small"
            sx={{ bgcolor: "black" }}
            onClick={handleClick}
          >
            Proceed to checkout
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CartRight;
