import { Button, Card, CardContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const Articles = () => {
  return (
    <Card sx={{ minWidth: 180, height: "99%", bgcolor: "azure" }}>
      <CardContent>
        <Stack spacing={3} justifyContent="space-around" alignItems="center">
          <Typography
            variant="p"
            sx={{
              fontSize: "16pt",
              bgcolor: "white",
              fontWeight: 700,
              textAlign: "center",
              //   paddingTop: "17%",
              borderTopRightRadius: "8px",
              borderTopLeftRadius: "8px",
            }}
          >
            Articles
          </Typography>
          <Stack
            spacing={1}
            justifyContent="center"
            alignItems="center"
            sx={{
              bgcolor: "white",
              width: "80%",
              padding: "2%",
              height: "auto",
              borderRadius: "5px",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              sx={{
                width: "100%",
                bgcolor: "black",
                color: "white",
                "&:hover": {
                  bgcolor: "white",
                  color: "black",
                },
              }}
            >
              Article-1
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{
                width: "100%",
                bgcolor: "black",
                color: "white",
                "&:hover": {
                  bgcolor: "white",
                  color: "black",
                },
              }}
            >
              Article-2
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{
                width: "100%",
                bgcolor: "black",
                color: "white",
                "&:hover": {
                  bgcolor: "white",
                  color: "black",
                },
              }}
            >
              Article-3
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{
                width: "100%",
                bgcolor: "black",
                color: "white",
                "&:hover": {
                  bgcolor: "white",
                  color: "black",
                },
              }}
            >
              Article-4
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{
                width: "100%",
                bgcolor: "black",
                color: "white",
                "&:hover": {
                  bgcolor: "white",
                  color: "black",
                },
              }}
            >
              Article-5
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Articles;
