import { Box } from '@mui/material';
import React from 'react';

const ReviewRight = () => {
    return (
        <Box>
            r
        </Box>
    );
};

export default ReviewRight;