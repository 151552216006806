import {
  Button,
  Container,
  FormControl,
  Grid,
  OutlinedInput,
  Pagination,
  Select,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { BookTypeApi } from "../components/Api/Api";
import { useNavigate } from "react-router-dom";

const Subject = () => {
  const navigate = useNavigate();
  const [age, setAge] = useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [data, setData] = React.useState([]);
  useEffect(() => {
    fetch(BookTypeApi)
      .then((res) => res.json())
      .then((result) => {
        setData(result);
      });
  }, []);
  return (
    <Container>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
        sx={{ marginY: { xs: "80px", sm: "50px" } }}
      >
        {" "}
        <Typography variant="h6">বিষয় সমূহ</Typography>
        <Stack direction="row">
          <OutlinedInput
            onChange={handleChange}
            size="small"
            placeholder="খোঁজ দ্য সার্চ"
            sx={{
              width: "70%",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
          />

          <Button
            sx={{
              border: "1px solid #dee2e6",
              width: "8%",
              padding: "0.43rem 0",
              marginLeft: "-1px",
              borderRadius: 0,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }}
          >
            <SearchIcon />
          </Button>
        </Stack>
      </Stack>

      <Grid
        container
        spacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
        columns={{ xs: 4, sm: 8, md: 12, lg: 16 }}
      >
        {data.map(
          (data) =>
            data?.type_name_bn && (
              <Grid item xs={12} sm={4} md={4} key={data.type_name_bn}>
                <Button
                  variant="outlined"
                  sx={{ width: "100%" }}
                  onClick={() => {
                    navigate("/bookfilter");
                  }}
                >
                  {data.type_name_bn}
                </Button>
              </Grid>
            )
        )}
      </Grid>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: "40px" }}
      >
        <Pagination
          count={10}
          variant="outlined"
          color="primary"
          sx={{ margin: "auto" }}
        />
      </Stack>
    </Container>
  );
};

export default Subject;
