import { Comment, ModeComment } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import subscription from "../../images/Subscription.jpg";

const CartItem = () => {
  return (
    <Card sx={{ width: "85%", height: "90%", padding: "15px" }}>
      <Stack sx={{}}>
        <Box sx={{ height: "50%", position: "relative" }}>
          <CardMedia
            component="img"
            height="100%"
            image={subscription}
            alt="green iguana"
          />
          <Box
            sx={{
              position: "absolute",
              bottom: "1%",
              left: 0,
              padding: "0 2%",
              bgcolor: "black",
              borderTopRightRadius: "5px",
            }}
          >
            <Typography
              variant="caption"
              sx={{ color: "white", fontWeight: 600 }}
            >
              Apple
            </Typography>
          </Box>
        </Box>

        <Stack spacing={0}>
          <Stack spacing={1} sx={{ padding: "1%" }}>
            <Typography
              gutterBottom
              variant="p"
              sx={{ fontSize: { xs: "10pt", md: "15pt" }, fontWeight: 700 }}
              component="div"
            >
              Mara Hoffman’s Spring 2015 Collection
            </Typography>
            <Stack spacing={7} direction="row" justifyContent="space-between">
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ fontSize: { xs: "7pt", md: "10pt" } }}
              >
                Matt Bryant - Sep 23, 2014
              </Typography>
              <Comment />
            </Stack>
            <Typography
              variant="p"
              color="text.secondary"
              sx={{ marginTop: "1%", fontSize: { xs: "8pt", md: "12pt" } }}
            >
              Happy Sunday from Software Expand! In this week's edition of
              Feedback Loop
            </Typography>
          </Stack>
          <Stack justifyContent="center" alignItems="center">
            <Button
              variant="contained"
              size="small"
              sx={{ marginBottom: "30px" }}
            >
              Buy now
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default CartItem;
