import React from "react";
import CartItem from "./CartItem";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";
import "./NovoBhobonList.css";

const NovoBhobonList = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        },
      },
    ],
  };
  return (
    <div>
      <Box
        className="carousel2"
        sx={{ marginTop: { xs: "8%", sm: "6%", md: "4%" } }}
      >
        <Slider {...settings}>
          <Box>
            <CartItem />
          </Box>
          <Box>
            <CartItem />
          </Box>
          <Box>
            <CartItem />
          </Box>
          <Box>
            <CartItem />
          </Box>
          <Box>
            <CartItem />
          </Box>
          <Box>
            <CartItem />
          </Box>
        </Slider>
      </Box>
      {/* <CartItem /> */}
    </div>
  );
};

export default NovoBhobonList;
