import { Container, Divider, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Slider from "react-slick";
import { topDealApi } from "../../Api/Api";
import CountDownTimer from "./DateCountdown/CountDownTimer";
import DayDealCard from "./DayDealCard";

const DealOfTheDay = () => {
  const [data, setData] = useState([]);
  console.log("booeerrre:", data);
  useEffect(() => {
    fetch(topDealApi)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setData(result);
      });
  }, []);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    draggable: true,
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ bgcolor: "#e8efed", paddingY: "20px" }}>
      <Container>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          // sx={{ padding: "0% 0" }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: "15pt", marginBottom: "30px" }}
          >
            টপ ডিল
          </Typography>
        </Stack>
        {/* <Divider sx={{ marginY: "20px" }} /> */}
        <Slider {...settings}>
          {data?.map((element) => (
            <Box key={element.type_name_bn}>
              <DayDealCard data={element} />
            </Box>
          ))}
        </Slider>
      </Container>
    </Box>
  );
};

export default DealOfTheDay;
